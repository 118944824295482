export default class HomeTabDocument {
    constructor($view) {
        this.view = $view;
        this.bind();
    }
    bind() {
        var url = $(this.view).data("contentUrl");
        var selector = $(this.view).data("contentSelector");
        $.fn.HomeTabDocument = {
            AddDocumentFailure: function () {
                Swal.fire(ResourceHomeTabDocument.TitleAddDocument, ResourceHomeTabDocument.DemandeAjoutDocumentErreur, "error");
            },
            UpdateDocumentList: function (data) {
                if (data.success == -1) {
                    Swal.fire(ResourceHomeTabDocument.TitleAddDocument, data.message, "error");
                }
                else {
                    $("#Modal").modal("hide");
                    $.ajax({
                        url: url,
                        method: "GET",
                        success: function (json) {
                            $(selector).html(json.content);
                            window.Kernel.mountComponents($(selector));
                            $("#appModal").modal("hide");
                        }
                    });
                }
            }
        }
    }
}