export default class DecimalSkillSectionLink {
    constructor($view) {
        this.view = $view;
        this.bind($view);
    }
    bind($view) {
        $($view).data("kendoNumericTextBox").bind("change", function () {
            var skill = $(this.element).data("skill");
            if (skill == "0") {
                return;
            }
            if (this.value() > 0) {
                //activate skill
                $.fn.createOrEditInfoTechniques.selectTypeCompetence(skill, true);
            }
        });
    };
}