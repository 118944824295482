export default class AskNewDate {
    constructor($view) {
        this.view = $view;
        this.bind();
    }

    bind() {

        $(document).ready(function () {
            $("#form-modal-newdate").kendoValidator().data("kendoValidator");
        });

        $.fn.AskNewDate = {
            OnBegin: function () {
                Swal.showLoading();
            },
            OnSuccess: function (datas) {
                Swal.close();
                if (datas.success == 1) {
                    $('#ModalCustom').modal('hide');
                    toastr.success(ResourceNewDate.OnSuccess);
                }
            },
            OnFailure: function (response) {
                Swal.close();
                if (response.statusText) {
                    toastr.error(response.statusText);
                }
                else {
                    toastr.error(ResourceNewDate.OnFailure);
                }
                return false;
            },
            OnChangeDebut: function () {
                var endPicker = $("#NewDateFin").data("kendoDatePicker"),
                    startDate = this.value();

                if (startDate) {
                    startDate = new Date(startDate);
                    startDate.setDate(startDate.getDate());
                    endPicker.min(startDate);
                }
            }
        };

    }
}