export default class ModalClose {
    constructor($view) {
        this.view = $view;
        this.bind();
    }

    bind() {
        $(() => {
            var jElement = $(this.view);
            var closeButtonSelectors = jElement.data("closeSelector");
            var modalId = jElement.data("modalContainer");
            var $modal = $('#' + modalId);
            $modal.on('hidden.bs.modal', function (event) {
                //sinon cela ajoute un padding-right au bout de plusieurs ouvertures/fermetures de modales
                $(body).css('padding-right', 0);
            });
            closeButtonSelectors.split(' ').forEach((selector) => {
                $(selector).on('click', function () {
                    $modal.modal('hide');
                });
            });
        });
    }
}

