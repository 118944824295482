export default class Alert {
    constructor($view) {
        this.view = $view;
        this.bind($view);
    }
    bind($view) {
        $.fn.Alert = {
            setPaddingToBoby: function () {
                var height = $($view).height();
                var heightTopBar = $(".topbar").height();
                $($view).css("top", heightTopBar);
                $("body.main-layout").css("padding-top", height + heightTopBar);
            }
        }
    };
}