
export default class MapHandler {
    constructor($view) {
        this.bind($view);
    }

    bind($view) {
        var view = $view;
        this.initMap(view);

    }

    initMap(view, includeAdresse = true, includeVille = true, includeCodePostal = true) {

        var self = this;
        var adresseVoie = $("#Adrevoie1").text();
        var codePostal = $("#CodePostal").text();
        var ville = $("#Ville").text();

        $.ajax({

            url: 'https://nominatim.openstreetmap.org/search',
            header: {
                "User-Agent": "6tm-Wipoz"
            },
            data: {
                street: includeAdresse ? adresseVoie : "",
                city: includeVille ? ville : "",
                postalcode: includeCodePostal ? codePostal : "",
                format: 'json',
                polygon: 1,
                limit: 1,
            },
            dataType: 'json',
            success: function (data) {

                // Dans le cas ou on a trouvé aucun point (du à une adresse incorrecte)
                // On réitère la demande sans l'addresse, le code postal et la ville à chaque itération sans données
                if (data.length == 0) {
                    if (includeAdresse) {
                        includeAdresse = !includeAdresse
                        $("#errorMap").removeClass("d-none");
                    }
                    else if (includeCodePostal)
                        includeCodePostal = !includeCodePostal
                    else if (includeVille)
                        includeVille = !includeVille
                    else
                        return;

                    return self.initMap($(view), includeAdresse, includeCodePostal, includeVille)
                }

                $(view).kendoMap({
                    center: [data[0].lat, data[0].lon],
                    zoom: 16,
                    layers: [{
                        type: "tile",
                        urlTemplate: "http://#= subdomain #.tile.openstreetmap.org/#= zoom #/#= x #/#= y #.png",
                        subdomains: ["a", "b", "c"],
                        attribution: "&copy; <a href='http://osm.org/copyright'>OpenStreetMap contributors</a>"
                    }],
                    markers: [{
                        location: [data[0].lat, data[0].lon],
                        shape: "pinTarget",
                    }]
                });
            }
        });
    }
}