export default class TabNavigator {
    constructor($view) {
        this.view = $view;
        this.bind($view);
    }
    bind($view) {
        var currentIndex = parseInt($($view).data("currentIndex"));
        var max = parseInt($($view).data("nbTab"));
        var tabStripId = $($view).data("tabStrip");
        if (currentIndex == 0) {
            $($view).find("[data-role=previous]").css("display", "none");
        }
        else {
            $($view).find("[data-role=previous]").on("click", function () {
                var nextTab = currentIndex - 1;
                if (nextTab < 0) {
                    return;
                }
                var tabStrip = $("#" + tabStripId).data("kendoTabStrip");
                tabStrip.select(nextTab);
            });
        }
        if ((currentIndex + 1) == max) {
            $($view).find("[data-role=next]").css("display", "none");
        }
        else {
            $($view).find("[data-role=next]").on("click", function () {
                var nextTab = currentIndex + 1;
                if (nextTab > max) {
                    return;
                }
                if (nextTab == (max - 1)) {
                    $.fn.createOrEdit.activateNext();
                }
                var tabStrip = $("#" + tabStripId).data("kendoTabStrip");
                tabStrip.enable($("#tab-" + nextTab), true);
                tabStrip.select(nextTab);
                
            });
        }
        
        
    };
}