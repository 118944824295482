export default class ToggleDescription {
    constructor($view) {
        this.view = $view;
        this.bind();
    }

    bind() {
        var $view = $(this.view);
        $view.find('input[type=radio]').on('change', function (event) {
            event.preventDefault();
            $view.find('[data-role=description]').addClass('d-none');
            var targetId = $(this).data("descriptionId");
            if (targetId) {
                if ($(this).is(':checked')) {
                    $('#' + targetId).removeClass('d-none');
                }
            }
        });
        var current = $view.find('input[type=radio]:checked');
        if (current) {
            var targetId = $(current).data("descriptionId");
            if (targetId) {
                $('#' + targetId).removeClass('d-none');
            }
        }
    }
}