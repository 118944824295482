export default class ActiveButton {
    constructor($view) {
        this.view = $view;
        this.bind();
    }

    bind() {
        $(document).ready(function () {
            $("input[type='checkbox'][data-confirm-required='true']").on('change', (e) => {
                var buttonId = $(e.currentTarget).data("confirmButton");
                var button = $("#" + buttonId);
                var isAllChecked = true;
                $("input[type='checkbox'][data-confirm-required='true']").each(function (i, el) {
                    if (!$(el).is(":checked")
                        && $(el).data("confirmButton") == buttonId
                        && isAllChecked) {
                        isAllChecked = false;
                    }
                });

                if (isAllChecked) {
                    button.removeAttr('disabled');
                    button.removeAttr('title');
                }
                else {
                    button.attr('disabled', 'disabled');
                    button.attr('title', button.data('disabled-title'));
                }
            });

            $("input[type='checkbox'][data-confirm-required='true']").each(function () {
                var buttonId = $(this).data("confirmButton");
                $("#" + buttonId).attr('disabled', 'disabled');
            });

        });
    }
}