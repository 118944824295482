import Swal from "sweetalert2";

export default class DocumentsNumeriques {
    constructor($view) {
        this.view = $view;
        this.bind();
    }

    bind() {

        var SignatureClient = new SignaturePad(document.getElementById('SignatureClient'));
        var SignaturePoseur = new SignaturePad(document.getElementById('SignaturePoseur'));

        $(document).ready(function () {
            $("#panelbar").kendoPanelBar();

            $.fn.DocumentsNumeriques.InitPanelBar();

            /************* Gestion de la completion en % *********************/
            /*****************************************************************/

            $("#form-certificat-fin-travaux input,textarea").change(function () {
                if($("#Ponderation").val() !== "OK"){
                    $.fn.DocumentsNumeriques.RefreshPanelBar();
                    $.fn.DocumentsNumeriques.RefreshPanelCheck();
                    $.fn.DocumentsNumeriques.RefreshPourcentageCompletion();
                }
            });

            /***** Gestion de l'affichage des BTN sur EP ou Appli mobile *****/
            /*****************************************************************/

            $('#EnregisterDocumentsNumeriquesHaut').click($.fn.DocumentsNumeriques.EnregisterDocumentsNumeriques);
            $('#ImprimerDocumentsNumeriquesHaut').click($.fn.DocumentsNumeriques.ImprimerDocumentsNumeriques);
            $('#EnvoyerClientFinalDocumentsNumeriquesHaut').click($.fn.DocumentsNumeriques.EnvoyerClientFinalDocumentsNumeriques);

            if ($('#ReadOnly').val() === "True" && $('#PointEntree').val() !== "api") {
                $('#AnnulerDocumentsNumeriquesHaut').hide();
                $("#panelbar").data("kendoPanelBar").enable($("#BlocSignatures"), true);
            }
            else if ($('#PointEntree').val() === "api") {
                if ($('#ReadOnly').val() === "True") {
                    $('#EnregisterDocumentsNumeriquesHaut').hide();
                }
                $('#AnnulerDocumentsNumeriquesHaut').hide();
                $('#FermerDocumentsNumeriquesHaut').hide();
                $('#ImprimerDocumentsNumeriquesHaut').hide();
            }

            /****************** Gestion des signatures ***********************/
            /*****************************************************************/
            $.fn.DocumentsNumeriques.InitialisationSignature();

           /*****************************************************************/
            $("#ButtonSignature").attr('disabled', 'disabled');
            $(".k-header .k-icon").after("<div class='pull-right checkok not-display'><i class='fa fa-check-circle-o' aria-hidden='true'></i></div>");

            if ($('#ReadOnly').val() === "False") {
                $.fn.DocumentsNumeriques.RefreshPourcentageCompletion();
            }
            $.fn.DocumentsNumeriques.RefreshPanelCheck()
        });

        $.fn.DocumentsNumeriques = {
            fermerDocumentsNumeriques: function () {
                if ($('#ReadOnly').val() === "True") {
                    window.location.href = urlActionDocumentsNumeriques.urlFicheProjet + "?Wipoz_ProjetID=" + $("#ProjetID").val();
                }
                else {
                    Swal.fire({
                        title: Resource.Titre_Fermeture,
                        text: Resource.Texte_Fermeture,
                        type: 'warning',
                        cancelButtonText: Resource.No,
                        confirmButtonText: Resource.Yes,
                        showCancelButton: true,
                        allowOutsideClick: () => !Swal.isLoading(),
                        confirmButtonColor: '#2ABEBA',
                        preConfirm: function () {
                            Swal.showLoading();
                            window.location.href = urlActionDocumentsNumeriques.urlFicheProjet + "?Wipoz_ProjetID=" + $("#ProjetID").val();
                        }
                    });
                }
            },
            ImprimerDocumentsNumeriques: function () {
                if ($(this).hasClass("disabled")) {
                    return;
                }

                Swal.fire({
                    title: Resource.TitreLoaderImprimer,
                    allowOutsideClick: () => !Swal.isLoading()
                });
                Swal.showLoading();

                var uri = "";
                $.ajax({
                    url: urlActionDocumentsNumeriques.urlImprimerDocumentNumerique,
                    method: "POST",
                    async: false,
                    data: { wipoz_ProjetID: $("#ProjetID").val(), wipoz_TypeDocumentID: $("#TypeDocumentIDSelected").val() },
                    success: function (data) {
                        Swal.close();
                        if (data.success === 1) {
                            toastr.success(data.message);
                            uri = data.chemin;
                        }
                        else if (data.success === 0) {
                            toastr.error(data.message);
                        }
                        else {
                            toastr.error(Resource.ErrorMsgImprimerDocumentNumerique);
                        }
                    },
                    error: function () {
                        Swal.close();
                        toastr.error(Resource.ErrorMsgImprimerDocumentNumerique);
                    }
                });
                if (uri != "") {
                    window.open(uri);
                }
            },
            EnvoyerClientFinalDocumentsNumeriques: function (event) {
                if ($(this).hasClass("disabled")) {
                    return;
                }

                Swal.fire({
                    title: Resource.TitreLoaderEnvoiClientFinal,
                    text: Resource.ConfirmEnvoiClientFinal,
                    cancelButtonText: Resource.No,
                    confirmButtonText: Resource.Yes,
                    showCancelButton: true,
                    confirmButtonColor: '#2ABEBA',
                    allowOutsideClick: () => !Swal.isLoading(),
                    preConfirm: function () {
                        Swal.showLoading();

                        $.ajax({
                            url: urlActionDocumentsNumeriques.urlEnvoyerClientFinalDocumentsNumeriques,
                            method: "POST",
                            async: false,
                            data: {
                                wipoz_ProjetID: $("#ProjetID").val(),
                                wipoz_TypeDocumentID: $("#TypeDocumentIDSelected").val(),
                                emailClient: $("#CoordonneesClient_ClientEmail").val()
                            },
                            success: function (data) {
                                Swal.close();
                                if (data.success === 1) {
                                    toastr.success(data.message);
                                }
                                else if (data.success === 0) {
                                    toastr.error(data.message);
                                }
                                else {
                                    toastr.error(Resource.ErrorMsgEnvoyerClientFinalDocumentsNumeriques);
                                }
                            },
                            error: function () {
                                Swal.close();
                                toastr.error(Resource.ErrorMsgEnvoyerClientFinalDocumentsNumeriques);
                            }
                        });
                    }
                });
            },
            EnregisterDocumentsNumeriques: function (e) {
                e.preventDefault();
                var typeDocumentIDSelected = $("#TypeDocumentIDSelected").val();
                switch (typeDocumentIDSelected) {
                    case documents.CFT: // Certificat de fin de travaux
                        if (!SignatureClient.isEmpty()) {
                            var signatureClient = SignatureClient.toDataURL('image/png');
                            $('#outputSignatureClient').val(signatureClient);
                        }
                       
                        if (!SignaturePoseur.isEmpty()) {
                            var signaturePoseur = SignaturePoseur.toDataURL('image/png');
                            $('#outputSignaturePoseur').val(signaturePoseur);
                        }

                        if ($.fn.CertificatFinTravaux.CertificatFinTravauxSuccessOnBegin()) {
                            $("#form-certificat-fin-travaux").submit();
                        }
                        else {
                            Swal.fire({
                                title: Resource.Titre_Enregistrer,
                                text: Resource.Texte_Enregistrer,
                                type: 'warning',
                                showCancelButton: false,
                                confirmButtonColor: '#2ABEBA'
                            });
                        }
                        break;
                }
            },
            IsSigned: function () {
                if (SignatureClient.isEmpty() && ($("#SigneParClient").val() === "False")) {
                    return false;
                }
                if (SignaturePoseur.isEmpty() && ($("#SigneParPoseur").val() === "False")) {
                    return false;
                }
                if (!$('#SignatureMention').is(':checked')) {
                    return false;
                }
                return true;
            },
            ResetSignatureClient: function () {
                $('#outputSignatureClient').val("");
                SignatureClient.clear();
            },
            InitialisationSignature: function () {
                $("#SigneParClient").removeAttr("data-val-required");
                $("#SigneParPoseur").removeAttr("data-val-required");

                if ($("#SigneParClient").val() === "False") {
                    $("#VisulClientForm").hide();
                    $("#SigantureClientForm").show();
                    $('#reinitialiserSignatureClient').click(function () {
                        $.fn.DocumentsNumeriques.ResetSignatureClient();
                    });
                }
                else {
                    $("#SigantureClientForm").hide();
                    $("#VisulClientForm").show();
                    $("#signVisuClient").attr("src", $('#outputSignatureClient').val());
                }

                if ($("#SigneParPoseur").val() === "False") {
                    $("#VisulPoseurForm").hide();
                    $('#reinitialiserSignaturePoseur').click(function () {
                        $('#outputSignaturePoseur').val("");
                        SignaturePoseur.clear();
                    });
                }
                else {
                    $("#SiganturePoseurForm").hide();
                    $("#signVisuPoseur").attr("src", $('#outputSignaturePoseur').val());
                }
            },
            RefreshPourcentageCompletion: function () {
                var ponderation = $.fn.DocumentsNumeriques.GetPourcentageCompletion("#form-certificat-fin-travaux");
                $("#DivPonderation").text(ponderation + " %");
                $.fn.DocumentsNumeriques.RefreshPanelCheck();
                if (ponderation === 100) {
                    $.fn.DocumentsNumeriques.SignerDocument()
                }
                else {
                    $("#IsSigned").val("False");
                    $("#Ponderation").val(ponderation);
                    $("#ButtonSignature").addClass('btn-signature-cursor');
                    $("#ButtonSignature").attr("disabled", "disabled");
                    if ($("#panelbar").data("kendoPanelBar") !== undefined) {
                        $("#panelbar").data("kendoPanelBar").collapse($("#BlocSignatures"), true);
                        $("#panelbar").data("kendoPanelBar").enable($("#BlocSignatures"), false);
                        $('#SignatureMention_validationMessage').addClass("hidden");
                    }
                }
            },
            RefreshPanelCheck: function () {
                $('#panelbar>li').each(function () {
                    var jQueryId = "#" + this.id;
                    if (jQueryId !== "#BlocSignatures") {
                        if ($.fn.DocumentsNumeriques.GetPourcentageCompletion(jQueryId) === 100) {
                            $(jQueryId + " > a > .checkok").show();
                        }
                        else {
                            $(jQueryId + " > a > .checkok").hide();
                        }
                    }
                    else if (etatCFT.IsSigne == 1
                        || $.fn.DocumentsNumeriques.IsSigned()) {
                        $(jQueryId + " > a > .checkok").show();
                    }
                });
            },
            InitPanelBar: function () {
                var isOneExpend = false;

                $('#panelbar>li').each(function () {
                    var jQueryId = "#" + this.id;
                    if (jQueryId !== "#BlocSignatures" && $("#panelbar").data("kendoPanelBar") !== undefined) {
                        if ($.fn.DocumentsNumeriques.GetPourcentageCompletion(jQueryId) === 100) {
                            $("#panelbar").data("kendoPanelBar").collapse($(jQueryId));
                        }
                        else if (!isOneExpend) {
                            $("#panelbar").data("kendoPanelBar").expand($(jQueryId));
                            $("#PanelBarIdExpend").val(this.id);
                            isOneExpend = true;
                        }
                    }
                });
            },
            RefreshPanelBar: function () {
                var panelSelected = document.getElementById($("#PanelBarIdExpend").val());
                var idPanelSelected = "#" + $("#PanelBarIdExpend").val();
                if (idPanelSelected !== "#BlocSignatures" && panelSelected !== null) {
                    if ($.fn.DocumentsNumeriques.GetCompletion(idPanelSelected) === 100) {
                        $("#panelbar").data("kendoPanelBar").collapse(idPanelSelected);
                        var NextPanel = $('#panelbar>li')[($('#panelbar>li').index(panelSelected) + 1)].id;
                        var idNextPanel = "#" + NextPanel;
                        $("#panelbar").data("kendoPanelBar").expand(idNextPanel);
                        $("#PanelBarIdExpend").val(NextPanel);
                    }
                }
            },
            GetPourcentageCompletion: function(conteneurId) {
                var conteneur = $(conteneurId);
                var totalObligatoires = 0;
                var totalNonRenseignes = 0;

                //Champ textes simples
                totalObligatoires += conteneur.find(".ChampsObligatoire").length;
                totalNonRenseignes += conteneur.find(".ChampsObligatoire").filter(function () { return !this.value; }).length;

                //Liste combobox et dropdownlist
                totalObligatoires += conteneur.find("input.ChampsObligatoireList:not(.k-input)").length;
                totalNonRenseignes += conteneur.find("input.ChampsObligatoireList:not(.k-input)").filter(function () { return !this.value; }).length;

                //radio button
                var listOfRadioButtonsName = jQuery.unique(conteneur.find(".ChampsObligatoireRadio").map(function () { return $(this).attr('Name'); }));
                totalObligatoires += listOfRadioButtonsName.length;
                listOfRadioButtonsName.each(function (index, radioButtonName) {
                    if (conteneur.find('[name=\'' + radioButtonName + '\']:checked').val() === undefined) {
                        totalNonRenseignes++;
                    }
                });
                var result = Math.round((totalObligatoires - totalNonRenseignes) / totalObligatoires * 100);

                if (isNaN(result)) {
                    result = 100;
                }
                return result;
            },
            CountRadioNonRenseignes: function (list, conteneur) {
                var count = 0;
                list.each(function (index, radioButtonName) {
                    if (conteneur.find('[name=\'' + radioButtonName + '\']:checked').val() === undefined) {
                        count++;
                    }
                });
                return count;
            },
            GetCompletion: function (conteneurId) {
                var conteneur = $(conteneurId);
                var totalObligatoires = 0;
                var totalNonRenseignes = 0;

                //Champ textes simples
                totalObligatoires += conteneur.find(".ChampsObligatoire").length;
                totalNonRenseignes += conteneur.find(".ChampsObligatoire").filter(function () { return !this.value; }).length;
                totalObligatoires += conteneur.find(".ChampsNonObligatoire").length;
                totalNonRenseignes += conteneur.find(".ChampsNonObligatoire").filter(function () { return !this.value; }).length;

                //Liste combobox et dropdownlist
                totalObligatoires += conteneur.find("input.ChampsObligatoireList:not(.k-input)").length;
                totalNonRenseignes += conteneur.find("input.ChampsObligatoireList:not(.k-input)").filter(function () { return !this.value; }).length;
                totalObligatoires += conteneur.find("input.ChampsNonObligatoireList:not(.k-input)").length;
                totalNonRenseignes += conteneur.find("input.ChampsNonObligatoireList:not(.k-input)").filter(function () { return !this.value; }).length;

                //radio button
                var listOfRadioButtonsName = jQuery.unique(conteneur.find(".ChampsObligatoireRadio").map(function () { return $(this).attr('Name'); }));
                totalObligatoires += listOfRadioButtonsName.length;
                totalNonRenseignes += $.fn.DocumentsNumeriques.CountRadioNonRenseignes(listOfRadioButtonsName, conteneur);
                listOfRadioButtonsName = jQuery.unique(conteneur.find(".ChampsNonObligatoireRadio").map(function () { return $(this).attr('Name'); }));
                totalObligatoires += listOfRadioButtonsName.length;
                totalNonRenseignes += $.fn.DocumentsNumeriques.CountRadioNonRenseignes(listOfRadioButtonsName, conteneur);

                //result
                var result = Math.round((totalObligatoires - totalNonRenseignes) / totalObligatoires * 100);

                if (isNaN(result)) {
                    result = 100;
                }

                return result;
            },
            ExpandPanelBar: function (e) {
                $("#PanelBarIdExpend").val(e.item.id);
            },
            SignerDocument: function () {
                var kendoPanelBar = $("#panelbar").data("kendoPanelBar");
                if ($("#IsSigned").val() === "False" && kendoPanelBar !== undefined) {
                    $("#IsSigned").val(true);
                    if (kendoPanelBar !== undefined) {
                        kendoPanelBar.collapse($("li", "#panelbar"));
                        kendoPanelBar.enable($("#BlocSignatures"), true);
                        kendoPanelBar.expand($("#BlocSignatures"), true);
                    }
                    var typeDocumentIDSelected = $("#TypeDocumentIDSelected").val();
                    switch (typeDocumentIDSelected) {
                        //Document : certificat de fin de travaux
                        case "16":
                            $.fn.CertificatFinTravaux.ChampObligatoirePourSignature();
                    }
                }
            },
            SuppressionImage: function (id) {
                Swal.fire({
                    title: Resource.TitreSupprimerFichier,
                    text: Resource.TexteSupprimerFichier,
                    type: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#2ABFBA',
                    cancelButtonColor: '#E0E0E0',
                    cancelButtonText: Resource.No,
                    confirmButtonText: Resource.Yes,
                    showLoaderOnConfirm: true,
                    allowEscapeKey: false,
                    allowOutsideClick: () => !Swal.isLoading(),
                    preConfirm: function () {
                        return fetch(urlActionDocumentsNumeriques.urlSupprimerFichier + "?" + new URLSearchParams({ fileId: id }).toString(), {
                            method: "GET",
                            credentials: 'include',
                        }).then(response => {
                            if (!response.ok) {
                                throw new Error(response.statusText)
                            }
                            $("#" + id).hide();
                            toastr.success(Resource.SuccessMsgSuppressionFichier);
                            return true;
                        }).catch(error => {
                            Swal.showValidationMessage(
                                Resource.ErrorMsgSuppressionFichier
                            );
                        });
                    }
                }).then(() => {
                });
            },
            UploadComplete: function (e) {
                toastr.success(Resource.UploadComplete);
                var zoneReload = $("#js-reload-" + e.sender.name);
                var typedocument = zoneReload.data('type');
                var projetId = zoneReload.data('projet');

                Swal.showLoading();
                $.ajax({
                    url: urlActionDocumentsNumeriques.urlChargerFichier,
                    data: {
                        projetId: projetId,
                        typeDocument: typedocument
                    },
                    method: "GET",
                    success: function (datas) {
                        Swal.close();
                        zoneReload.html(datas);
                    },
                    error: function () {
                        Swal.close();
                        toastr.error(Resource.UploadCompleteError);
                    }
                });
            },
            UploadSuccess: function (e) { },
            UploadError: function (e) {
                switch (e.XMLHttpRequest.status) {
                    case 400:
                        toastr.error(Resource.UploadErrorProjet);
                        break;
                    case 403:
                        toastr.error(Resource.UploadErrorEtat);
                        break;
                    default:
                        toastr.error(Resource.UploadErrorGenerique);
                        break;
                }
            },
        }
    }
}