export default class Soldes {
    constructor($view) {
        this.view = $view;
        this.bind();
    }
    bind() {

        var soldeYes = $(this.view).find("[data-role=SoldeYes]");
        var soldeNo = $(this.view).find("[data-role=SoldeNo]");
        var soldeValue = $(this.view).find("[data-role=SoldeValue]");
        var soldeForm = $(this.view).find("[data-role=SoldeForm]");

        if ($(soldeNo).is(":checked")) {
            $(soldeForm).find("span").attr('disabled', 'disabled');
            $(soldeValue).removeAttr("data-val-required");
        }
        else {
            $(soldeForm).find("span").removeAttr('disabled');
            $(soldeValue).attr("data-val-required", $(soldeValue).data('isrequiredsolde'));
        }

        $(soldeValue).change(function () {
            validateForm();
        });

        $(soldeValue).blur(function () {
            validateForm();
        });

        $(soldeYes).on("click", function () {
            if ($(this).is(":checked")) {
                $(soldeForm).find("span").removeAttr('disabled');
                $(soldeValue).attr("data-val-required", $(soldeValue).data('isrequiredsolde'));
                validateForm();
            }
        });

        $(soldeNo).on("click", function () {
            if ($(this).is(":checked")) {
                $(soldeForm).find("span").attr('disabled', 'disabled');
                var numerictextbox = $(soldeValue).data("kendoNumericTextBox");
                numerictextbox.value("");
                $(soldeValue).removeAttr("data-val-required");
                validateForm();
            }
        });
        
        function validateForm() {
            $('#form-projet').data("kendoValidator").validate();
        }
    }
}