
export default class RegisterFormActions {
    constructor($view) {
        this.view = $view;
        this.bind();
    }

    bind() {

        var validator;
        var currentTab = 0;

        window.InscriptionSuccess = function (data) {
            Swal.close();
            if (data.Success == "OkInscriptionInprogress") {
                Swal.fire(Resource.Demande, RegisterFormActionsRsx.RegisterSuccessMessage, "success").then((value) => {
                    if (data.redirectTo) {
                        window.location.assign(data.redirectTo);
                    }
                    else {
                        window.location.href = "/";
                    }
                });
            } else {
                NextPrev(-1);
                Swal.fire(Resource.Inscription, data.Message, "error");
            }
        }

        window.onValidForm = function () {
            if (!$('input[name=IsCGU]').is(":checked")) {
                toastr.error("La validation des cgu est obligatoire");
                return false;
            }
        }

        window.OnBegin = function () {
            Swal.showLoading();
        }

        window.InscriptionError = function (data) {
            Swal.close();
            Swal.fire(Resource.Inscription, RegisterFormActionsRsx.RegisterErrorMessage, "error");
        }

        // Inscription poseur
        window.InitValidator = function () {
            validator = $("#FormInscription").kendoValidator({
                validationSummary: true
            }).data("kendoValidator");
        }

        window.FixStepIndicator = function (n) {
            var i, x = document.getElementsByClassName("step");
            for (i = 0; i < x.length; i++) {
                $(x[i]).removeClass("active");
            }
            $(x[n]).addClass("active");
        }

        window.ShowTab = function (n) {
            var x = document.getElementsByClassName("tab");
            $(x[n]).show();
            if (n == 0) {
                $("#prevBtn").hide();
                $("#cancelBtn").show();
            } else {
                $("#prevBtn").show();
                $("#cancelBtn").hide();
            }
            if (n == (x.length - 1)) {
                $("#nextBtn").hide();
                $("#submitBtn").show();
            } else {
                $("#nextBtn").show();
                $("#submitBtn").hide();
            }
            FixStepIndicator(n)
        }

        window.ValidateForm = function () {
            if (validator.validate()) {
                $(document.getElementsByClassName("step")[currentTab]).addClass("finish");
                return true;
            }
            else {
                return false;
            }
        }

        window.NextPrev = function (n) {
            var x = document.getElementsByClassName("tab");
            if (n == 1 && !ValidateForm()) {
                return false;
            }
            $(x[currentTab]).hide();
            currentTab = currentTab + n;
            if (currentTab >= x.length) {
                $("#FormInscription").submit();
                return false;
            }
            ShowTab(currentTab);
        }
    }
}