export default class ToggleFavorite {
    constructor($view) {
        this.view = $view;
        this.bind();
    }

    bind() {
        var $view = $(this.view);
        if ($view.data('isFavorite') == 'True') {
            $view.text($view.data('remove-text'));
        }
        else {
            $view.text($view.data('add-text'));
        }
        this.view.on("click", function (event) {
            event.preventDefault();
            $.ajax({
                url: $view.data('url'),
                type: "POST",
                contentType: "application/json; charset=utf-8",
                dataType: "json",
                success: function (data) {
                    $view.data('isFavorite', data.isFavorite);
                    var icon = $('#wipoz-favorite-icon');
                    var iconBlackliste = $('#blacklistes');
                    icon.removeClass('d-none');
                    if (data.isFavorite) {
                        $view.text($view.data('remove-text'));
                        iconBlackliste.removeClass("blacklistes");
                        iconBlackliste.addClass("not-blacklistes");
                    }
                    else {
                        $view.text($view.data('add-text'));
                        icon.addClass('d-none');
                        $('#direct-favorite-icon').addClass('d-none');
                    }
                }
            });
        });
    };
}