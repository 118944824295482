export default class NumericCheckBox {
    constructor($view) {
        this.bind($view);
    }
    bind(view) {
        var me = $(view);
        me.val(me.is(":checked") ? 1 : 0);
        me.next('input[type=hidden]').val(view.checked ? 1 : 0);
        me.on('change', function () {
            $(this).val(this.checked ? 1 : 0);
        });
    }
}