export default class FileGrid {
    constructor($view) {
        this.view = $view;
        this.bind($view);
    }

    bind($view) {
        $("#" + $view[0].id + " tbody").on("dblclick", "td", function (e) {
            var cellElement = this;
            var cell = $(cellElement);
            var p = cell.closest(".k-grid");
            var grid = $(p).data("kendoGrid");
            grid.editCell(cell);
        });

        $("#" + $view[0].id + " tbody").on("blur", "td", function (e) {
            var cellElement = this;
            var cell = $(cellElement);
            var p = cell.closest(".k-grid");
            var grid = $(p).data("kendoGrid");
            grid.closeCell(cell);
            grid.dataSource.sync();
        });
    }
}