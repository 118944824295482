export default class createOrEditInfoPlanningBudget {

    constructor($view) {
        this.view = $view;
        this.bind();
    }

    bind() {
        $.fn.createOrEdit.activateNext();
        
        $("#form-projet").kendoValidator({
            validate: function (ev) {
                $(".k-invalid:first").focus();
            },
            rules: {
                issmallerdatethandatelivraison: function (input) {
                    if (input.is("[data-issmallerdatethandatelivraison-msg]") && input.val() != "") {
                        var date = kendo.parseDate(input.val()),
                            otherDate = kendo.parseDate($("[name='" + input.data("issmallerdatethandatelivraisonField") + "']").val());
                        return otherDate == null || date.getTime() < otherDate.getTime();
                    }
                    return true;
                },
                issmallerdatethandatepose: function (input) {
                    if (input.is("[data-issmallerdatethandatepose-msg]") && input.val() != "") {
                        var date = kendo.parseDate(input.val()),
                            otherDate = kendo.parseDate($("[name='" + input.data("issmallerdatethandateposeField") + "']").val());
                        return otherDate == null || date.getTime() < otherDate.getTime();
                    }
                    return true;
                },
                issmallerorequaldatethan: function (input) {
                    if (input.is("[data-issmallerorequaldatethan-msg]") && input.val() != "") {
                        var date = kendo.parseDate(input.val()),
                            otherDate = kendo.parseDate($("[name='" + input.data("issmallerorequaldatethanField") + "']").val());
                        return otherDate == null || date.getTime() <= otherDate.getTime();
                    }

                    return true;
                },
                isbiggerorequaldatethan: function (input) {
                    if (input.is("[data-isbiggerorequaldatethan-msg]") && input.val() != "") {
                        var date = kendo.parseDate(input.val()),
                            otherDate = kendo.parseDate($("[name='" + input.data("isbiggerorequaldatethanField") + "']").val());
                        return otherDate == null || date.getTime() >= otherDate.getTime();
                    }

                    return true;
                },
                issmallerdatethanDateFinDeMetre: function (input) {
                    if (input.is("[data-issmallerdatethanDateFinDeMetre-msg]") && input.val() != "") {
                        var date = kendo.parseDate(input.val()),
                            otherDate = kendo.parseDate($("[name='DateFinDeMetre']").val());
                        return otherDate == null || date.getTime() <= otherDate.getTime();
                    }

                    return true;
                },
                isBiggerdatethanDateDeMetre: function (input) {
                    if (input.is("[data-isbiggerdatethanDateDeMetre-msg]") && input.val() != "") {
                        var date = kendo.parseDate(input.val()),
                            otherDate = kendo.parseDate($("[name='DateDeMetre']").val());
                        return otherDate == null || date.getTime() >= otherDate.getTime();
                    }

                    return true;
                },
                issmallerdatethanDateDebutFlexibleFin: function (input) {
                    if (input.is("[data-issmallerdatethanDateDebutFlexibleFin-msg]") && input.val() != "") {
                        var date = kendo.parseDate(input.val()),
                            otherDate = kendo.parseDate($("[name='DateDebutFlexibleFin']").val());
                        return otherDate == null || date.getTime() <= otherDate.getTime();
                    }

                    return true;
                },
                isBiggerdatethanDateDebutFlexibleDebut: function (input) {
                    if (input.is("[data-isBiggerdatethanDateDebutFlexibleDebut-msg]") && input.val() != "") {
                        var date = kendo.parseDate(input.val()),
                            otherDate = kendo.parseDate($("[name='DateDebutFlexibleDebut']").val());
                        return otherDate == null || date.getTime() >= otherDate.getTime();
                    }

                    return true;
                }
            }
        });

        $.fn.createOrEditInfoPlanningBudget = {
            initialize: function () {
                $(function () {
                    const msgDateMetreObligatoire = $("#DateDeMetre").data('isrequiredsimetrearealiser');
                    const msgDateFinMetreObligatoire = $("#DateFinDeMetre").data('isrequiredsimetrearealiser');
                    const msgDateLivraisonObligatoire = $("#DateDeLivraison").data('isrequiredsimetrearealiser');
                    $(document).ready(function () {
                        // La nature du projet n'a pas le métré
                        if (($("#ManagementType").data("value") & managementType.measure) == 0) {
                            $("#dateMetreDebutForm").find("span").attr('disabled', 'disabled');
                            $("#dateMetreFinForm").find("span").attr('disabled', 'disabled');
                        }
                        else {
                            $("#DateDeMetre").attr("data-val-required", msgDateMetreObligatoire);
                            $("#DateFinDeMetre").attr("data-val-required", msgDateFinMetreObligatoire);
                            $("#dateMetreDebutForm").find("span").removeAttr('disabled');
                            $("#dateMetreFinForm").find("span").removeAttr('disabled');
                        }

                        if ($("#CheckboxADefinir").is(":checked") == true) {
                            $("#dateLivraisonForm").find("span").attr('disabled', 'disabled');
                            $("#DateDeLivraison").val("");
                            $("#DateDeLivraison").removeAttr("data-val-required");
                            $("#WeekDateDeLivraison").text("");
                        }
                        else if ($(this).is(":checked") == false) {
                            $("#dateLivraisonForm").find("span").removeAttr('disabled');
                            $("#DateDeLivraison").attr("data-val-required", msgDateLivraisonObligatoire);
                        }

                        $("#WeekDateDebut").text($.fn.createOrEditInfoPlanningBudget.getNumWeek($("#DateDebut").val()));
                        $("#WeekDateDebutFlexibleDebut").text($.fn.createOrEditInfoPlanningBudget.getNumWeek($("#DateDebutFlexibleDebut").val()));
                        $("#WeekDateDebutFlexibleFin").text($.fn.createOrEditInfoPlanningBudget.getNumWeek($("#DateDebutFlexibleFin").val()));
                        $("#WeekDateDeLivraison").text($.fn.createOrEditInfoPlanningBudget.getNumWeek($("#DateDeLivraison").val()));
                        $("#WeekDateDeMetre").text($.fn.createOrEditInfoPlanningBudget.getNumWeek($("#DateDeMetre").val()));
                        $("#WeekDateFinDeMetre").text($.fn.createOrEditInfoPlanningBudget.getNumWeek($("#DateFinDeMetre").val()));

                        if ($("#FlexibleImperativeDate2").is(":checked") !== true) {
                            $("#FlexibleImperativeDateGroup").addClass('d-none');
                            $("#WeekDateDebutFlexibleDebut").text(null);
                            $("#WeekDateDebutFlexibleFin").text(null);
                            $("#DateDebutFlexibleDebut").removeAttr("data-val-required");
                            $("#DateDebutFlexibleFin").removeAttr("data-val-required");
                        }
                    });

                    $("#DateDebutFlexibleDebut").change(function () {
                        $("#WeekDateDebutFlexibleDebut").text($.fn.createOrEditInfoPlanningBudget.getNumWeek($("#DateDebutFlexibleDebut").val()));
                        $("#form-projet").data("kendoValidator").validate();
                    });

                    $("#DateDebutFlexibleFin").change(function () {
                        $("#WeekDateDebutFlexibleFin").text($.fn.createOrEditInfoPlanningBudget.getNumWeek($("#DateDebutFlexibleFin").val()));
                        $("#form-projet").data("kendoValidator").validate();
                    });

                    $("#DateDebut").change(function () {
                        $("#WeekDateDebut").text($.fn.createOrEditInfoPlanningBudget.getNumWeek($("#DateDebut").val()));
                        $("#form-projet").data("kendoValidator").validate();
                    });

                    $("#DateDebut").blur(function () {
                        $("#form-projet").data("kendoValidator").validate();
                    });

                    $("#DateDeLivraison").change(function () {
                        $("#WeekDateDeLivraison").text($.fn.createOrEditInfoPlanningBudget.getNumWeek($("#DateDeLivraison").val()));
                        $("#form-projet").data("kendoValidator").validate();
                    });

                    $("#DateDeLivraison").blur(function () {
                        $("#form-projet").data("kendoValidator").validate();
                    });

                    $("#DateDeMetre").change(function () {
                        $("#WeekDateDeMetre").text($.fn.createOrEditInfoPlanningBudget.getNumWeek($("#DateDeMetre").val()));
                        $("#form-projet").data("kendoValidator").validate();
                    });

                    $("#DateDeMetre").blur(function () {
                        $("#form-projet").data("kendoValidator").validate();
                    });

                    $("#DateFinDeMetre").change(function () {
                        $("#WeekDateFinDeMetre").text($.fn.createOrEditInfoPlanningBudget.getNumWeek($("#DateFinDeMetre").val()));
                        $("#form-projet").data("kendoValidator").validate();
                    });

                    $("#DatefinDeMetre").blur(function () {
                        $("#form-projet").data("kendoValidator").validate();
                    });

                    $("#CheckboxADefinir").on("click", function () {
                        if ($(this).is(":checked") == true) {
                            $("#dateLivraisonForm").find("span").attr('disabled', 'disabled');
                            $("#DateDeLivraison").val("");
                            $("#DateDeLivraison").removeAttr("data-val-required");
                            $("#WeekDateDeLivraison").text("");
                            $("#form-projet").data("kendoValidator").validate();
                        }
                        else if ($(this).is(":checked") == false) {
                            $("#dateLivraisonForm").find("span").removeAttr('disabled');
                            $("#DateDeLivraison").data("valRequired", msgDateLivraisonObligatoire);
                            $("#form-projet").data("kendoValidator").validate();
                        }
                    });

                    $("#FlexibleImperativeDate1").on("click", function () {
                        if ($(this).is(":checked")) {
                            $("#FlexibleImperativeDateGroup").addClass('d-none');
                            $("#WeekDateDebutFlexibleDebut").text(null);
                            $("#WeekDateDebutFlexibleFin").text(null);
                            $("#DateDebutFlexibleDebut").removeAttr("data-val-required");
                            $("#DateDebutFlexibleDebut").data("kendoDatePicker").value(null);

                            $("#DateDebutFlexibleFin").removeAttr("data-val-required");
                            $("#DateDebutFlexibleFin").data("kendoDatePicker").value(null);

                            $("#form-projet").data("kendoValidator").validate();
                        }
                    });

                    $("#FlexibleImperativeDate2").on("click", function () {
                        if ($(this).is(":checked")) {
                            $("#FlexibleImperativeDateGroup").removeClass('d-none');
                            $("#DateDebutFlexibleDebut").attr("data-val-required", msgDateMetreObligatoire);
                            $("#DateDebutFlexibleFin").attr("data-val-required", msgDateMetreObligatoire);  
                            $("#form-projet").data("kendoValidator").validate();
                        }
                    });
                })
            },
            getNumWeek: function (dateInput) {
                var result;
                if (dateInput) {
                    var parts = dateInput.split("/");
                    var d = new Date(Number(parts[2]), Number(parts[1]) - 1, Number(parts[0]));
                    d = new Date(Date.UTC(d.getFullYear(), d.getMonth(), d.getDate()));
                    // Set to nearest Thursday: current date + 4 - current day number
                    // Make Sunday's day number 7
                    d.setUTCDate(d.getUTCDate() + 4 - (d.getUTCDay() || 7));
                    // Get first day of year
                    var yearStart = new Date(Date.UTC(d.getUTCFullYear(), 0, 1));
                    // Calculate full weeks to nearest Thursday
                    var weekNo = Math.ceil((((d - yearStart) / 86400000) + 1) / 7);
                    // Return array of year and week number 
                    result = ResourceCreationProjet.SemaineNumero + " " + weekNo;
                } else {
                    result = "";
                }
                return result;
            },

            cbTypeFacturation: function (value) {
                $("#BudgetEstimeGroup").find("input").attr("aria-valuenow", value);
                $("#BudgetEstimeGroup").find("input").val(value);
            },
        };
        $.fn.createOrEditInfoPlanningBudget.initialize();
    }
}