import Swal from "sweetalert2";

export default class Uploader {
    constructor($view) {
        this.view = $view;
        this.bind($view);
    }
    bind($view) {
        $.fn.Uploader = {
            onUploadStart: function () {
                Swal.fire({
                    title: ResourceCreationProjet.SauvegardeEnCours,
                    allowOutsideClick: () => !Swal.isLoading()
                });
                Swal.showLoading();
            },
            onUploadSuccess: function (e) {
                Swal.close();
                var gridId = $(e.sender.element).data('gridId');
                if (gridId) {
                    var grid = $('#' + gridId).data('kendoGrid');
                    if (grid) {
                        grid.dataSource.read();
                    }
                }
            },
            onUploadError: function (e) {
                if (e.XMLHttpRequest != null
                    && e.XMLHttpRequest.responseURL != null
                    && e.XMLHttpRequest.status == 200
                ) {
                    // Cas de la d�connexion
                    window.location = e.XMLHttpRequest.responseURL;
                }
                toastr.error(ResourceCreationProjet.SauvegardeError);
                Swal.close();
            }
        }
    };
}