export default class SelectTitleFromText {
    constructor($view) {
        this.bind($view);
    }
    bind($view) {
        $($view).find("option:selected").each((index, element) => {
            $($view).prop('title', $(element).text());
        });
        $($view).on('change', function () {
            $(this).find("option:selected").each((index, element) => {
                 $($view).prop('title', $(element).text());
            });
        });
    }
}