import Swal from "sweetalert2";

export default class Panel {

    constructor($view) {
        this.view = $view;
        this.bind();
    }

    bind() {
        $(function () {
            $('a[data-toggle-panel]').on('click', (e) => {
                let targetPanel = $(e.currentTarget).data('togglePanel');
                $(targetPanel).toggleClass('open');
            });

        });

        $.fn.panel = {
            updateFavorites: function () {
                $.ajax({
                    url: $("#boutonPanneauFavoris").data("url"),
                    method: "GET",
                    async: false, // Mode synchrone
                    data: {  },
                    success: function (data) {
                        var panel = $(".js-panel-favorites .panel-body");
                        panel.html(data);
                        //les composants ne sont pas mont�s dans le panel par d�faut
                        window.Kernel.mountComponents(panel);
                    },
                    error: function () {
                    }
                });
            },

            updateFavorisDe: function () {
                $.ajax({
                    url: $("#boutonPanneauFavorisDe").data("url"),
                    method: "GET",
                    async: false, // Mode synchrone
                    data: {},
                    success: function (data) {
                        var panel = $(".js-panel-favorites-of .panel-body");
                        panel.html(data);
                        //les composants ne sont pas mont�s dans le panel par d�faut
                        window.Kernel.mountComponents(panel);
                    },
                    error: function () {
                    }
                });
            },

            updateBlacklistes: function () {
                $.ajax({
                    url: $("#boutonPanneauBlackListe").data("url"),
                    method: "GET",
                    async: false, // Mode synchrone
                    data: {  },
                    success: function (data) {
                        var panel = $(".js-panel-blacklistes .panel-body");
                        panel.html(data);
                        //les composants ne sont pas mont�s dans le panel par d�faut
                        window.Kernel.mountComponents(panel);
                    },
                    error: function () {
                    }
                });
            },

            deleteBlackliste: function (id, type) {
                var titre;
                var texte;
                var tooltip;

                if (type == Profil.Cuisiniste) {
                    titre = Resource.Blackliste_Retirer_Poseur_Titre;
                    texte = Resource.Blackliste_Retirer_Poseur_Texte;
                    tooltip = Resource.ClientPoseurBlacklistes_Tooltip_NotBlacklistes;
                }
                else if(type == Profil.Poseur) {
                    titre = Resource.Blackliste_Retirer_Cuisiniste_Titre;
                    texte = Resource.Blackliste_Retirer_Cuisiniste_Texte;
                    tooltip = Resource.ClientCuisinisteBlacklistes_Tooltip_NotBlacklistes;
                }

                Swal.fire({
                    title: titre,
                    text: texte,
                    type: "question",
                    showCancelButton: true,
                    confirmButtonColor: '#2ABFBA',
                    cancelButtonColor: '#E0E0E0',
                    cancelButtonText: Resource.Non,
                    confirmButtonText: Resource.Oui,
                    showLoaderOnConfirm: true,
                    allowEscapeKey: false,
                    allowOutsideClick: () => !Swal.isLoading(),
                    preConfirm: () => {
                        $.ajax({
                            url: Actions.DeleteBlackliste,
                            method: "POST",
                            async: false,
                            data: {
                                id: id,
                            },
                            success: function (datas) {
                                if (datas.success == 0) {
                                    toastr.error(datas.message);
                                }
                                else {
                                    var panel = $(".js-panel-blacklistes .panel-body");
                                    panel.html(datas);
                                    //les composants ne sont pas mont�s dans le panel par d�faut
                                    window.Kernel.mountComponents(panel);

                                    // On s'assure de mettre � jour le bon poseur
                                    $(".banPoseurblacklistes[data-clientid='" + id + "']").removeClass('blacklistes').addClass('not-blacklistes');
                                    $(".banPoseurblacklistes[data-clientid='" + id + "']").attr("title", tooltip);
                                }
                            },
                            error: function () {
                                Swal.close();
                                toastr.error(CollaborateurResource.ErreurTraitement);
                            }
                        });
                    },
                    onAfterClose: function () {
                        $(window).scrollTop(0);
                    }
                }).then();
            }
        };

        window.toggleblacklistes = function () {
            var src = $(window.event.srcElement);
            var id = src.data("clientid");
            var url = src.data("url");
            $.ajax({
                url: url,
                method: "POST",
                data: { id: id },
                success: function (datas) {
                    if (datas.success === 0) {
                        if (datas.message) {
                            toastr.error(datas.message);
                        }
                        else {
                            toastr.error(Resource.ClientBlacklistes_AddError);
                        }
                    }
                    else {
                        if (datas.success === 1) {
                            $('.banPoseurblacklistes').removeClass('not-blacklistes').addClass('blacklistes');
                            if (datas.poseur === 0) {
                                $('.starPoseurFavoris').removeClass('favoris').addClass('not-favoris');
                                $('.starPoseurFavoris').attr("title", Resource.ClientFavori_Tooltip_NotFavoris);

                                $('.banPoseurblacklistes').attr("title", Resource.ClientPoseurBlacklistes_Tooltip_IsBlacklistes);
                                toastr.success(Resource.ClientPoseurBlacklistes_AddSuccess);
                            }
                            else {
                                $('.banPoseurblacklistes').attr("title", Resource.ClientCuisinisteBlacklistes_Tooltip_IsBlacklistes);
                                toastr.success(Resource.ClientCuisinisteBlacklistes_AddSuccess);
                            }
                        }
                        else if (datas.success === 2) {
                            $('.banPoseurblacklistes').removeClass('blacklistes').addClass('not-blacklistes');
                            if (datas.poseur === 0) {
                                $('.banPoseurblacklistes').attr("title", Resource.ClientPoseurBlacklistes_Tooltip_NotBlacklistes);
                                toastr.success(Resource.ClientPoseurBlacklistes_RemoveSuccess);
                            }
                            else {
                                $('.banPoseurblacklistes').attr("title", Resource.ClientCuisinisteBlacklistes_Tooltip_NotBlacklistes);
                                toastr.success(Resource.ClientCuisinisteBlacklistes_RemoveSuccess);
                            }
                        }
                    }
                },
                error: function () {
                    toastr.error(Resource.ClientBlacklistes_AddError);
                }
            });
        }
    }
}