export default class FacturationList {
    constructor($view) {
        this.view = $view;
        this.bind($view);
    }

    bind() {
        var exportExcelFlag = false;
        $.fn.facturationList = {
            refreshGridFacturation: function () {
                $('#GridFacturation').data('kendoGrid').dataSource.read();
                $('#GridFacturation').data('kendoGrid').refresh();
            },
            gridFacturation_onDataBound: function () {
                // Spécifique WebPack
                // On attends bien que Kendo ai chargé les templates pour relancer le binding des components
                window.Kernel.mountComponents($('#GridFacturation'));

                $.fn.facturationList.customKendoSearchBar();
                $.fn.facturationList.checkEditCondition();
                $.fn.facturationList.autoColumnWidth();
            },
            gridFacturation_ExcelExport: function (e) {
                if (!exportExcelFlag) {
                    e.sender.hideColumn("UploadFactureFile");
                    e.sender.hideColumn("TVAFacturation");
                    e.sender.showColumn("Taux_TVA");
                    e.sender.autoFitColumn("Taux_TVA");

                    e.preventDefault();
                    exportExcelFlag = true;
                    setTimeout(function () {
                        e.sender.saveAsExcel();
                    });

                } else {
                    e.sender.hideColumn("Taux_TVA");
                    e.sender.showColumn("TVAFacturation");
                    e.sender.showColumn("UploadFactureFile");
                    exportExcelFlag = false;
                }
            },
            checkEditCondition: function () {
                var grid = $("#GridFacturation").data("kendoGrid");
                $(".btn-edit").each(function (i, element) {
                    var tr = $(element).closest("tr");
                    var data = grid.dataItem(tr);
                    if (data.DateReglement && data.Status == 3) {
                        $(element).hide();
                    }
                });
            },
            customKendoSearchBar: function () {
                if ($("#searchBar").val() === undefined) {
                    var gridSearch = $("span.k-grid-search");
                    $(gridSearch).wrap("<div id='searchBar' class='pull-right'></div>")
                    $("#searchBar").prepend("<span>" + Resource.rechercherTitle + ": </span");
                    $(".k-grid-search .k-input").addClass("filter-grid stringSearch form-control search-input searchi");
                    $(".k-grid-search .k-input").removeAttr("placeholder");
                    $(".k-grid-search .k-input").attr("title", Resource.rechercherParCuisinisteOuContremarque);
                }
            },
            autoColumnWidth: function () {
                var grid = $('#GridFacturation').data('kendoGrid');
                grid.autoFitColumn("DateReglement");
                grid.autoFitColumn("UrlFacture");
            },
            ValiderPaiement: function (id) {
                $.fn.facturationList.confirm(Resource.validerPaiementTitle, Resource.validerPaiementText)
                    .then(result => {
                        if (result.value) {
                            $.ajax({
                                url: urlAction.validerPaiement,
                                method: "POST",
                                contentType: "application/json; charset=utf-8",
                                dataType: "json",
                                data: JSON.stringify({
                                    projetFactureId: id,
                                }),
                                success: function () {
                                    $.fn.facturationList.refreshGridFacturation();
                                }
                            });
                        }
                        Swal.close();
                    });
            },
            confirm: function (title, text) {
                return Swal.fire({
                    title: title,
                    text: text,
                    type: "warning",
                    showCancelButton: true,
                    confirmButtonColor: '#2ABFBA',
                    cancelButtonColor: '#E0E0E0',
                    cancelButtonText: Resource.nonButtonText,
                    confirmButtonText: Resource.ouiButtonText,
                    closeOnConfirm: false,
                    allowOutsideClick: false
                });
            },

            // UploadFactureFileEditor
            onUploadFileChange: function (e) {
                var lineEditBtn = $(".k-grid-edit-row").children(".line-edit-btn");
                var uploadBtn = lineEditBtn.children(".k-grid-update");
                var error = e.files[0].validationErrors;
                if (error !== undefined && error.length > 0) {
                    uploadBtn.attr("disabled", "disabled")
                    uploadBtn.addClass("btn-save-disabled");
                    toastr.error(Resource.extensionFileError);
                }
                else if ($(".line-edit-btn.active.erreur") !== undefined) {
                    uploadBtn.removeAttr("disabled");
                    uploadBtn.removeClass("btn-save-disabled");
                }
                uploadFileChange = true;
            },
            onRemoveFile: function () {
                var lineEditBtn = $(".k-grid-edit-row").children(".line-edit-btn");
                var uploadBtn = lineEditBtn.children(".k-grid-update");
                uploadBtn.removeAttr("disabled");

                uploadFileChange = false;
            },
            successUploadFactureFile: function () {
                $.fn.facturationList.refreshGridFacturation();
                toastr.success(Resource.toastrSuccessUpload);
            },

            failureUploadFactureFile: function () {
                $.fn.facturationList.refreshGridFacturation();
                toastr.error(Resource.toastrErrorUpload);
            },

            // EtatFacturationPoseurEditor
            onEtatFacturationPoseurDatabound: function () { },

            // TVAFacturationEditor
            onTVAChange: function (e) {
                tauxTVAChange = true;
                var dropdownlist = e.sender.element.data("kendoDropDownList");
                $("#Taux_TVA").val(dropdownlist.text());
            },

            // DateReglementFacturationEditor
            onDatePickerChange: function () {
                dateReglementChange = true;
            },
        }
    }
}