import Swal from "sweetalert2";

export default class ValidateMeasurement {
    constructor($view) {
        this.view = $view;
        this.bind();
    }

    bind() {

        $(document).ready(function () {
            var submitButton = $("button[type='submit']");
            submitButton.attr('title', submitButton.data('disabled-title'));

            $('#BoutonAnnulerModal').on('click', () => {
                $('#ModalCustom').modal('hide');
            })
        });

        $.fn.ValidateMeasurement = {
            OnSuccess: function (data, status, xhr) {
                if (data.redirect_url) {
                    Swal.showLoading();
                    window.location.assign(data.redirect_url);
                }
            },

            OnFailure: function (response) {
                if (response.statusText) {
                    toastr.error(response.statusText);
                }
                else {
                    toastr.error(ResourcePublication.ImpossiblePublierProjet);
                }
                return false;
            }
        };

    }
}