export default ($view) => {

    $view.on("click", (event) => {
        //stop la propagation de l'évènement click
        event.preventDefault();

        var href = $view.data("href");
        var url = $view.data("ajaxUrl");
        var method = $view.data("ajaxMethod") ?? "POST";
        var param = {};
        var cb = $view.data("confirmCallback");
        if ($view.data('ajaxParametersFunction')) {
            param = convertStringtoFunction($view.data('parametersFunction'))(event);
        }
        else if ($view.data('ajaxParametersData')) {
            param = JSON.parse($view.data('parametersData'));
        }

        if (url) {
            initSwal($view, function (response) {
                if (!response.value) {
                    return;
                }

                if (cb) {
                    var functionCB = convertStringtoFunction(cb);
                    if (functionCB) {
                        functionCB($view, response.value);
                    }
                }

                if (response.value.success && href) {
                    window.location.assign(href);
                }
            }, function () {
                return $.ajax({
                    url: url,
                    method: method,
                    data: param,
                    statusCode: {
                        301: function (jqXHR) {
                            window.location.assign(jqXHR.getResponseHeader("Location"));
                        },
                        302: function (jqXHR) {
                            window.location.assign(jqXHR.getResponseHeader("Location"));
                        },
                        303: function (jqXHR) {
                            window.location.assign(jqXHR.getResponseHeader("Location"));
                        }
                    }
                }).done((data, textStatus, jqXHR) => {
                    return {
                        success: true,
                        data: data
                    };
                }).fail((jqXHR, textStatus, errorThrown) => {
                    return {
                        success: false,
                        data: null
                    };;
                });
            });
        }
        else {
            initSwal(
                $view,
                function (isConfirm) {

                    if (cb) {
                        var functionCB = convertStringtoFunction(cb);
                        if (functionCB) {
                            functionCB($view, isConfirm.value);
                            return;
                        }
                    }

                    if (isConfirm.value && href) {
                        window.location.assign(href);
                    }

                });
        }
    });

    function initSwal($view, $onConfirm, preConfirm) {
        var props = mergeProps($view);
        if (preConfirm) {
            props.showLoaderOnConfirm = true;
            props.allowOutsideClick = () => !Swal.isLoading();
            props.preConfirm = preConfirm;
        }
        Swal.fire(props).then((result) => $onConfirm(result));
    }

    function mergeProps($view) {
        let defaultProps = getDefaultProps($view);
        return Object.assign(defaultProps, $view.data());
    }

    function getDefaultProps($view) {
        return {
            title: "",
            type: "info",
            html: $view.find(".swal-confirm-content").html(),
            showCancelButton: true,
            confirmButtonColor: "#2ABFBA",
            cancelButtonColor: "#E0E0E0",
            cancelButtonText: "Annuler",
            confirmButtonText: "Valider",
            showLoaderOnConfirm: false,
            allowEscapeKey: false,
        };
    }
}
