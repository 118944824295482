export default class Grid {
    constructor($view) {
        this.view = $view;
        this.bind();
    }
    bind() {

        $.fn.Grid = {
            onEdit: function (e) {
                let update = $(e.container).parent().find(".k-grid-update");
                let cancel = $(e.container).parent().find(".k-grid-cancel");
                $(update).html('<span class="k-icon k-update"></span><div class="icone" title="' + $.fn.Grid.GetTextButton(update) + '"><i class="fa fa-save btn green-brand-wipoz" aria-hidden="true"></i></div>');
                $(cancel).html('<span class="k-icon k-cancel"></span><div class="icone" title="' + $.fn.Grid.GetTextButton(cancel) + '"><i class="fa-solid fa-arrow-right-to-bracket btn blue-brand-wipoz" aria-hidden="true"></i></div>');
            },
            onRemove: function (e) {
                e.preventDefault();
                var tr = $(e.target).closest("tr"); //get the row for deletion
                var data = this.dataItem(tr); //get the row data so it can be referred later
                var grid = this;
                var $grid = $(grid.element);
                Swal.fire({
                    title: $grid.data('deleteConfirmTitle'),
                    text: $grid.data('deleteConfirmMessage'),
                    type: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#2ABEBA',
                    cancelButtonText: Resource.Non,
                    confirmButtonText: Resource.Oui,
                    showLoaderOnConfirm: true,
                    preConfirm: () => {
                        grid.dataSource.remove(data);
                        grid.dataSource.sync();
                    },
                    allowOutsideClick: () => !Swal.isLoading()
                }).then((result) => {
                });
            },
            GetTextButton: function (e) {
                if ($(e).length > 0) {
                    return $(e)[0].innerText;
                }
                return "";
            },
            add: function (e) {
                var grid = null;
                var gridElement = $(e.element).parent(".k-grid");
                if (! gridElement) {
                }
                else {
                    var gridName = $(e.element).data("grid-name");
                    if (gridName) {
                        gridElement = $('#' + gridName);
                    }
                }
                if (gridElement) {
                    grid = gridElement.data("kendoGrid");
                }
                if (! grid) {
                    grid = this;
                }
                if (grid) {
                    grid.addRow();
                }
                else {
                    toastr.error(Resource.Error_Traitement);
                    console.error("impossible de retrouver la grid associée à la commande");
                }
            },
            onError: function (e) {
                if (e.xhr.responseJSON
                    && e.xhr.responseJSON.message
                ) {
                    toastr.error(e.xhr.responseJSON.message);
                }
                else {
                    toastr.error(Resource.Error_Traitement);
                }
            }
        }
    }
}