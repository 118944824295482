export default class Collaborateur {
    constructor($view) {
        this.view = $view;
        this.bind();
    }
    bind() {
        $.fn.Collaborateur = {
            UpdateBlocCollaborateur: function (datas) {
                toastr.success(datas.message);
                $("#js-collaborateurs").html(datas.collaborateurs);
                $(".indicateur").addClass("d-none");
                if (datas.indicateur > 0) {
                    $("#js-indicateur-" + datas.indicateur).removeClass("d-none");
                }
                if (datas.indicateur > 1) {
                    // Les indicateurs 2 et 3 ne permettent de rajouter des collaborateurs
                    $("#addCollaborateur").hide();
                }
                window.Kernel.mountComponents($("#js-collaborateurs"));
                $("#ModalContent").modal("hide");
                Swal.close();
            },
            AddCollaborateurBegin: function () {
                Swal.showLoading();
            },
            AddCollaborateurFailure: function () {
                Swal.close();
                Swal.fire($(".modal-title").html(), CollaborateurResource.ErreurTraitement, "error");
            },
            AddCollaborateurSuccess: function (data) {
                if (data.success == -1) {
                    Swal.close();
                    Swal.fire($(".modal-title").html(), data.message, "error");
                }
                else {
                    $.fn.Collaborateur.UpdateBlocCollaborateur(data);
                }
            },
            SendNewpassword: function (id) {
                Swal.showLoading();
                $.ajax({
                    url: CollaborateurActions.NewPassword,
                    method: "POST",
                    async: false,
                    data: {
                        id: id,
                    },
                    success: function (datas) {
                        if (datas.success == -1) {
                            Swal.close();
                            Swal.fire(CollaborateurResource.TitreEnvoiMotDePasse, datas.message, "error");
                        }
                        else {
                            $("#ModalContent").modal("hide");
                            toastr.success(datas.message);
                            Swal.close();
                        }
                    },
                    error: function () {
                        Swal.close();
                        toastr.error(CollaborateurResource.ErreurTraitement);
                    }
                });
            },
            Desactivated: function (id) {
                Swal.fire({
                    title: CollaborateurResource.TitreDesactivation,
                    text: CollaborateurResource.ConfirmationDesactivation,
                    type: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#2ABFBA',
                    cancelButtonColor: '#E0E0E0',
                    cancelButtonText: CollaborateurResource.Non,
                    confirmButtonText: CollaborateurResource.Oui,
                    showLoaderOnConfirm: true,
                    allowOutsideClick: () => !Swal.isLoading(),
                    allowEscapeKey: false,
                    preConfirm: () => {
                        $.ajax({
                            url: CollaborateurActions.Desactivated,
                            method: "POST",
                            async: false,
                            data: {
                                id: id,
                            },
                            success: function (datas) {
                                if (datas.success == -1) {
                                    Swal.close();
                                    Swal.fire(CollaborateurResource.TitreDesactivation, datas.message, "error");
                                }
                                else {
                                    $.fn.Collaborateur.UpdateBlocCollaborateur(datas);
                                }
                            },
                            error: function () {
                                Swal.close();
                                toastr.error(CollaborateurResource.ErreurTraitement);
                            }
                        });
                    },
                }).then((result) => {});
            },
        }
    }
}