export default class NumericTextBox {
    constructor($view) {
        this.view = $view;
        this.bind();
    }
    bind() {
        var $view = $(this.view);
        var input = $view.find('input[type=number]');
        var precision = parseInt($(input).data("precision"));
        $(input).on('change', function () {
            var current = parseFloat(input.val());
            input.val(current.toFixed(precision));
        });
        $view.find('[data-command=next]').on('click', function (e) {
            var step = $(input).prop('step');
            if (step != '' && step != 'any') {
                step = parseFloat(step);
            }
            else {
                step = 1;
            }
            var max = $(input).prop('max');
            if (max != '') {
                max = parseInt(max);
            }
            var current = 0;
            if (input.val()) {
                current = parseFloat(input.val());
            }
            current = current + step;
            if ((typeof (max) == 'number') && (current > max)) {
                current = max;
            }
            input.val(current.toFixed(precision));
        });
        $view.find('[data-command=prev]').on('click', function (e) {
            var step = $(input).prop('step');
            var localPrecision = precision;
            if (step != '' && step != 'any') {
                step = parseFloat(step);
            }
            else {
                step = 1;
            }
            var min = $(input).prop('min');
            if (min != '') {
                min = parseInt(min);
            }
            var current = 0;
            if (input.val())
            {
                current = parseFloat(input.val());
            }
            current = current - step;
            if ((typeof(min) == 'number') && (current < min)) {
                current = min;
            }
            if (current == min && precision > 0) {
                localPrecision = 0;
            }
            input.val(current.toFixed(localPrecision));
        });
    }
}