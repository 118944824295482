export default ($view) => {

    $view.on("click", (event) => {
        //stop la propagation de l'évènement click
        event.preventDefault();

        var url = $view.data("ajaxUrl");
        var method = $view.data("ajaxMethod") ?? "POST";
        var param = {};
        if ($view.data('ajaxParametersFunction')) {
            param = convertStringtoFunction($view.data('parametersFunction'))(event);
        }
        else if ($view.data('ajaxParametersData')) {
            param = JSON.parse($view.data('parametersData'));
        }
        var cb = $view.data("ajaxCallback");
        var functionCB = null;
        if (cb) {
            functionCB = convertStringtoFunction(cb);
        }
        $.ajax({
            url: url,
            method: method,
            data: param,
            statusCode: {
                301: function (jqXHR) {
                    window.location.assign(jqXHR.getResponseHeader("Location"));
                },
                302: function (jqXHR) {
                    window.location.assign(jqXHR.getResponseHeader("Location"));
                },
                303: function (jqXHR) {
                    window.location.assign(jqXHR.getResponseHeader("Location"));
                }
            }
        }).done((data, textStatus, jqXHR) => {
            if (functionCB) {
                functionCB({
                    success: true,
                    data: data
                });

            }
        }).fail((jqXHR, textStatus, errorThrown) => {
            if (functionCB) {
                functionCB({
                    success: false,
                    data: null
                });
            }
        });

    });
}
