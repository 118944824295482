import { error } from "toastr";

export default class CertificatFinTravaux {
    constructor($view) {
        this.view = $view;
        this.bind();
    }

    bind() {
        var clientAbsent = "data: image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAHcAAACGCAIAAABolYJhAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAARrSURBVHhe7ZvhgYMgDIVvrg7kPJ2myzjMHSKSRIJo1Xfavu/fYQzkE9Gq9 / NLzoeWEdAyAlpGQMsIaBkBLSOgZQS0jICWEdAyAlpGQMsIaBkBLSOgZQS0jICWEdAyAlpGQMsIaBkBLSOgZQS0jICWEdAyAlpGQMsIaBkBLSOgZQS0jICWEdAygutYfnWPn4FH9 + pT08fwH5b7Vxd9BqPPLDS3mdYP4RTL / evZPR7j1IyEP7rnq0 / y + ue0ybfcvVLbx3C05TBPld0ZSaprGbdiSPeww3msZTUhPRYtw7i5ZeN4mJJZYR / XEFrejwy + Zc + 1LJfEcsUY1 / m0OewVF / m0bSTvHcUNy1buQsfWzrWzdR9mWRfQGLRvuTbBawt9OFlSRCDv3T3Vwc5MoTXLZ59TR1nWM7k1MzZY1mlLpKPluJzz7nNZjb85MdZbNlnTiR + Wj2w0h1rLqbl / uoOS2LPlZi5tWZJaH2Wssqy7b / REy7YpXMI0RaybstnT7SzL0NuDb9SeG9WRq / FllrdM5rWWpanK5MlN2ezpfpbNzFv23KjdsbycL1AJbfR0Q8uzE3zrbz + vUdpsuimfaHJTNnsK + 8dWlfkcDrS8YiEdrTRq154aGd + y7CQ1O53AoZZDWUuP5KZiNljW7SUSuclyqdl2eTwHW46Mjx20nNnz5Vylqs5tTMyeYwz5wi9p / SijtnctqX7SMdwnzns8mDMskzm0jICWEdAyAlpGQMsIaBkBLSOgZQS0jICWEdAyAlpGQMsIaBkByPLS0 + Mv4AjL9nWGq7H2JuRLOMDy / J2R5 / HylmWAZ7zY3m95LtkVScv7yMN7yDetpUla3oWS3MtIi6Fay / rd5sP5P5LevPz0vgoPzF65XvrT8Z2WtWT1VzFEZdm + 3R7RE1wl0dhzoPZBwkU / Hd9n2Upe0FxRJ + SSVtS8nEw6bnWaUl59Ls8l1zWbgqc1Ipzz0jovWc3KeK6LZWXlLp + O77EsRrzRmuHW7CtjY7sKzAotsov1IXtOwzG5JFUZGJDGi1n2JJvS1HjdygbEWdqg9o / ML2oqU9iiKbqodOo2S + O1LKtpWEMGXCnYsRyQ4IzciKzo9oMsr6hWjXiT5Ui8UUubImm7cwjmTL3e3 / IqyV7BtgqVxq8u3 / NOTirqPG5vWWr3BqWcpkJUS2jL9xjqUKU8oSn + TnHKL1OZwCt / Ov6e5WXJZsxjJarBZ8qjxVtEST0m8pZlJ6nZaR9vWW5JNmOOg82VuT / D1L1xzaAKCSwdNJGzyXLZ8z9bbksOMeJhCBLLwx / 6wjYtH5m4NW8OAe5TjDIuBppIWZGssErzEC8JQzKn0zd59 + pHtkDLCGgZAS0joGUEtIyAlhHQMgJaRkDLCGgZAS0joGUEtIyAlhHQMgJaRkDLCGgZAS0joGUEtIyAlhHQMgJaRkDLCGgZAS0joGUEtIyAlhHQMgJaRkDLCGgZAS0joGUEtIyAlhHQMgJaRkDLCGgZAS0joGUEtIyAls / n9 / cPvym4R7rCAGYAAAAASUVORK5CYII=";
        (function ($) {
            $.fn.goTo = function (id) {
                document.getElementById(id).scrollIntoView(false);
            }
        })(jQuery);
        var currentState = {
            PoseDevantClient: null,
            isNew: $('#DocumentsNumeriquesID').val() === '00000000-0000-0000-0000-000000000000',
            loading:true
        };
        $.fn.CertificatFinTravaux = {
            onClientPresent: function (present) {
                $('#ClientPoseDevantClient_validationMessage').addClass("hidden");
                if (present) {
                    $('#SignatureDroitImageDiv').show();
                    $.fn.CertificatFinTravaux.InitialisationClientPresent();
                    if (currentState.PoseDevantClient === false) { //on passe de client non présent à client présent
                        $.fn.DocumentsNumeriques.ResetSignatureClient();
                        $("#ReservesObservations").val("");
                        $("#NotationAppreciation").val("");
                        document.getElementById("Reserves1").checked = false;
                        document.getElementById("Reserves2").checked = false;
                        $.fn.CertificatFinTravaux.onReserveClient();
                    }
                }
                else {
                    $('#SignatureDroitImageDiv').hide();
                    $.fn.CertificatFinTravaux.InitialisationClientNonPresent();
                }
            },
            motifSoldeNonRemis: function (remis) {
                if (remis) {
                    $("#SoldeMotif").hide();
                    $.fn.CertificatFinTravaux.nonRequiredTextBox("#MotifNonRemiseSolde");
                    $("#MotifNonRemiseSolde").val('');
                }
                else {
                    $("#SoldeMotif").show();
                    $.fn.CertificatFinTravaux.requiredTextBox("#MotifNonRemiseSolde");
                }
            },
            nonRequiredTextBox: function (id) {
                $(id).removeAttr("data-val-required");
                $(id).addClass("ChampsNonObligatoire");
                $(id).removeClass("ChampsObligatoire");
            },
            requiredTextBox: function (id) {
                $(id).attr("data-val-required", Resource.ChampObligatoire);
                $(id).addClass("ChampsObligatoire");
                $(id).removeClass("ChampsNonObligatoire");
            },
            soldePercuCeJour: function (required) {
                if ($('#ReadOnly').val() === "False") {
                    $.fn.CertificatFinTravaux.typeReglement(required);
                    $.fn.CertificatFinTravaux.soldeSomme(required);
                    if (required) {
                        $('#SoldePercuCeJour1,#SoldePercuCeJour2').removeClass("ChampsNonObligatoireRadio");
                        $('#SoldePercuCeJour1,#SoldePercuCeJour2').addClass("ChampsObligatoireRadio");
                        $("#SoldePercuCeJour_validationMessage").removeClass("hidden");
                    }
                    else if ($('#SoldePercuCeJour2').is(':checked') || !$('#SoldePercuCeJour1').is(':checked')) {
                        $('#SoldePercuCeJour1,#SoldePercuCeJour2').addClass("ChampsNonObligatoireRadio");
                        $('#SoldePercuCeJour1,#SoldePercuCeJour2').removeClass("ChampsObligatoireRadio");
                        $("#SoldePercuCeJour_validationMessage").addClass("hidden");
                    }
                }

            },
            typeReglement: function (required) {
                if (required) {
                    $('#TypeReglementCheque,#TypeReglementEspece').addClass("ChampsObligatoireRadio");
                    $('#TypeReglementCheque,#TypeReglementEspece').removeClass("ChampsNonObligatoireRadio");
                    $('#TypeReglement_validationMessage').removeClass("hidden");
                    $('#SoldeTypeReglementDiv,#SoldeTypeReglementLabel').show();
                }
                else {
                    $('#TypeReglementCheque, #TypeReglementEspece').prop("checked", false);
                    $('#TypeReglementCheque,#TypeReglementEspece').removeClass("ChampsObligatoireRadio");
                    $('#TypeReglementCheque,#TypeReglementEspece').addClass("ChampsNonObligatoireRadio");
                    $('#TypeReglement_validationMessage').addClass("hidden");
                    $('#SoldeTypeReglementDiv,#SoldeTypeReglementLabel').hide();
                }
            },
            onReserveClient: function () {
                $('#Reserves_validationMessage').addClass("hidden");
                var observations = $("#ReservesObservations");
                if ($('#Reserves1').is(':checked')) {
                    observations.attr("data-val-required", Resource.ChampObligatoire);
                    observations.removeClass("ChampsNonObligatoire");
                    observations.addClass("ChampsObligatoire");
                }
                else {
                    observations.removeAttr("data-val-required");
                    observations.removeClass("ChampsObligatoire");
                    observations.addClass("ChampsNonObligatoire");
                }
                var validator = $("#form-certificat-fin-travaux").kendoValidator().data("kendoValidator");
                validator.validateInput(observations);
                $.fn.DocumentsNumeriques.RefreshPourcentageCompletion();
            }, 
            soldeSomme: function (required) {
                var solde = $("#SoldeSomme");
                if (required) {
                    if (!$("#Solde1").is(':checked') && !$("#Solde2").is(':checked')) {
                        $('#Solde_validationMessage').removeClass("hidden");
                    }
                    solde.attr("data-val-required", Resource.ChampObligatoire);
                    solde.addClass("ChampsObligatoireList");
                    solde.removeClass("ChampsNonObligatoireList");
                }
                else {
                    $('#Solde_validationMessage').addClass("hidden");
                    solde.removeAttr("data-val-required");
                    solde.removeClass("ChampsObligatoireList");
                    solde.addClass("ChampsNonObligatoireList");
                }
                var validator = $("#form-certificat-fin-travaux").kendoValidator().data("kendoValidator");
                validator.validateInput(solde);
            },
            onSoldeAPercevoir: function (flag) {
                if (flag) {
                    $("#BodySoldePercevoirPoseur").show();
                    $.fn.CertificatFinTravaux.motifSoldeNonRemis(false);
                }
                else {
                    //pas de solde à récupérer
                    $("#BodySoldePercevoirPoseur").hide();
                    $.fn.CertificatFinTravaux.motifSoldeNonRemis(true);
                }
                $.fn.CertificatFinTravaux.soldeSomme(flag);
                $.fn.CertificatFinTravaux.soldePercuCeJour(flag);
                $.fn.CertificatFinTravaux.typeReglement(flag);
                $.fn.DocumentsNumeriques.RefreshPourcentageCompletion();
            },
            InitialisationCertificatFinTravaux: function (isNew) {
                if ($('#ReadOnly').val() === "False") {
                    $.fn.DocumentsNumeriques.RefreshPourcentageCompletion();
                }

                $('#ClientPoseDevantClient1,#ClientPoseDevantClient2').on('click', () => {
                    $.fn.CertificatFinTravaux.onClientPresent($('#ClientPoseDevantClient1').is(':checked'));
                    currentState.PoseDevantClient = $('#ClientPoseDevantClient1').is(':checked');
                    $.fn.DocumentsNumeriques.RefreshPourcentageCompletion();
                });

                /**** bloc Detail travail effectué */

                $('#DetailProtectionChantier1,#DetailProtectionChantier2').on('click', () => {
                    $('#DetailProtectionChantier_validationMessage').addClass("hidden");
                    $.fn.DocumentsNumeriques.RefreshPourcentageCompletion();
                });

                $('#DetailMontageInstallation1,#DetailMontageInstallation2').on('click', () => {
                    $('#DetailMontageInstallation_validationMessage').addClass("hidden");
                    $.fn.DocumentsNumeriques.RefreshPourcentageCompletion();
                });

                $('#DetailMiseEnPlaceElectro1,#DetailMiseEnPlaceElectro2').on('click', () => {
                    $('#DetailMiseEnPlaceElectro_validationMessage').addClass("hidden");
                    $.fn.DocumentsNumeriques.RefreshPourcentageCompletion();
                });

                $('#DetailRaccordement1,#DetailRaccordement2').on('click', () => {
                    $('#DetailRaccordement_validationMessage').addClass("hidden");
                    $.fn.DocumentsNumeriques.RefreshPourcentageCompletion();
                });

                $('#DetailControleFonctionnement1,#DetailControleFonctionnement2').on('click', () => {
                    $('#DetailControleFonctionnement_validationMessage').addClass("hidden");
                    $.fn.DocumentsNumeriques.RefreshPourcentageCompletion();
                });


                $('#DetailControleArriveesEvacuations1,#DetailControleArriveesEvacuations2').on('click', () => {
                    $('#DetailControleArriveesEvacuations_validationMessage').addClass("hidden");
                    $.fn.DocumentsNumeriques.RefreshPourcentageCompletion();
                });

                $('#DetailControleDefautAspects1,#DetailControleDefautAspects2').on('click', () => {
                    $('#DetailControleDefautAspects_validationMessage').addClass("hidden");
                    $.fn.DocumentsNumeriques.RefreshPourcentageCompletion();
                });

                $('#DetailNettoyageFinChantier1,#DetailNettoyageFinChantier2').on('click', () => {
                    $('#DetailNettoyageFinChantier_validationMessage').addClass("hidden");
                    $.fn.DocumentsNumeriques.RefreshPourcentageCompletion();
                });


                $('#DetailSavPrevoir1,#DetailSavPrevoir2').on('click', () => {
                    $('#DetailSavPrevoir_validationMessage').addClass("hidden");
                    $.fn.DocumentsNumeriques.RefreshPourcentageCompletion();
                });

                $('#NotationNote1,#NotationNote2,#NotationNote3,#NotationNote4,#NotationNote5,#NotationNote6,#NotationNote7,#NotationNote8,#NotationNote9,#NotationNote10').on('click', () => {
                    $('#NotationNote_validationMessage').addClass("hidden");
                    $.fn.DocumentsNumeriques.RefreshPourcentageCompletion();
                });

                $('#Reserves1,#Reserves2').on('click', () => {
                    $.fn.CertificatFinTravaux.onReserveClient();
                });
                /* bloc Detail travail effectué ****/

                $('#Solde1,#Solde2').on('click', () => {
                    $.fn.CertificatFinTravaux.onSoldeAPercevoir($('#Solde1').is(':checked'));
                    $('#Solde_validationMessage').addClass("hidden");
                });

                $('#TypeReglementEspece,#TypeReglementCheque').on('click', () => {
                    $('#TypeReglement_validationMessage').addClass("hidden");
                    $.fn.DocumentsNumeriques.RefreshPourcentageCompletion();
                });

                $('#SoldePercuCeJour1,#SoldePercuCeJour2').on('click', () => {
                    $('#SoldePercuCeJour_validationMessage').addClass("hidden");
                    $.fn.CertificatFinTravaux.soldeSomme($('#SoldePercuCeJour1').is(':checked'));
                    $.fn.CertificatFinTravaux.typeReglement($('#SoldePercuCeJour1').is(':checked'));
                    $.fn.CertificatFinTravaux.motifSoldeNonRemis($('#SoldePercuCeJour1').is(':checked'));
                    $.fn.DocumentsNumeriques.RefreshPourcentageCompletion();
                });

                $('#SignatureMention').on('click', () => {
                    $('#SignatureMention_validationMessage').addClass("hidden");
                    if ($('#SignatureMention').is(':checked')) {
                        $('#ImprimerDocumentsNumeriquesHaut').removeClass("disabled");
                        $('#EnvoyerClientFinalDocumentsNumeriquesHaut').removeClass("disabled");
                    }
                    $.fn.DocumentsNumeriques.RefreshPourcentageCompletion();
                });

                $("#form-certificat-fin-travaux").kendoValidator({
                    validate: function (ev) {
                        if (ev.valid) {
                            Swal.fire({
                                title: Resource.TitreLoaderEnregistrer,
                                allowOutsideClick: () => !Swal.isLoading()
                            });
                            Swal.showLoading();
                        }
                        else {
                            $(".k-invalid:first").focus();
                        }
                    }
                }).data("kendoValidator");
                if ($('#ClientPoseDevantClient1').is(':checked') || $('#ClientPoseDevantClient2').is(':checked')) {
                    $.fn.CertificatFinTravaux.onClientPresent($('#ClientPoseDevantClient1').is(':checked'));
                    currentState.PoseDevantClient = $('#ClientPoseDevantClient1').is(':checked');
                }
                if ($('#Reserves2').is(':checked') || ($('#Reserves1').not(':checked') && $('#Reserves2').not(':checked'))) {
                    $('#ReservesObservations').removeClass("ChampsObligatoire");
                }

                if ($('#Solde1').is(':checked')) {
                    $.fn.CertificatFinTravaux.onSoldeAPercevoir(true);
                    if ($('#SoldePercuCeJour1').is(':checked')) {
                        $.fn.CertificatFinTravaux.soldePercuCeJour(true);
                        $('#SoldePercuCeJour_validationMessage').addClass("hidden");
                        $.fn.CertificatFinTravaux.typeReglement(true);
                        $('#TypeReglement_validationMessage').addClass("hidden");
                    }
                    else if ($('#SoldePercuCeJour2').is(':checked')) {
                        $.fn.CertificatFinTravaux.typeReglement(false);
                        $('#TypeReglement_validationMessage').addClass("hidden");
                        $.fn.CertificatFinTravaux.soldePercuCeJour(false);
                    }
                }
                else if ($('#Solde2').is(':checked')) {
                    $.fn.CertificatFinTravaux.onSoldeAPercevoir(false);
                    $.fn.CertificatFinTravaux.soldePercuCeJour(false);
                    $.fn.CertificatFinTravaux.typeReglement(false);
                }
                else {
                    $.fn.CertificatFinTravaux.onSoldeAPercevoir(false);
                }

                if ($('#ClientPoseDevantClient2').is(':checked')) {
                    $("#NotationAppreciation_validationMessage").addClass("hidden");
                    $("#NotationDiv").hide();
                }

                $("#SignatureDroitImage").removeAttr("data-val-required");
                $("#SignatureMention").removeAttr("data-val-required");
                $("#SignatureLe").removeAttr("data-val-date");
                currentState.loading = false;

                $.fn.CertificatFinTravaux.CertificatFinTravauxSuccessOnBegin();
                Swal.close();
            },

            InitialisationClientNonPresent: function () {
                if (!currentState.isNew && currentState.loading) { return; }
                document.getElementById("Reserves2").checked = true;
                $('#Reserves_validationMessage').addClass("hidden");
                $("#ReservesObservations").val(Resource.ClientAbsent);
                $("#NotationAppreciation").val(Resource.ClientAbsent);
                $("#NotationAppreciation_validationMessage").addClass("hidden");
                $("#NotationDiv").hide();
                document.getElementById("SignatureMention").checked = true;
                $("#SignatureMention_validationMessage").addClass("hidden");
                $("#SigantureClientForm").hide();
                $("#signVisuClient").attr("src", clientAbsent);
                $("#VisulClientForm").show();
                $('#outputSignatureClient').val(clientAbsent);
                document.getElementById("TypeReglementCheque").checked = false;
                document.getElementById("TypeReglementEspece").checked = false;
                document.getElementById("SoldePercuCeJour1").checked = false;
                document.getElementById("SoldePercuCeJour2").checked = false;
            },

            InitialisationClientPresent: function () {
                if (currentState.isNew) {
                    $("#ReservesObservations").val("");
                    $("#NotationAppreciation").val("");
                }
                $("#NotationDiv").show();
                $("#SigantureClientForm").show();
                $("#VisulClientForm").hide();
            },

            CertificatFinTravauxSuccessOnBegin: function () {
                var NoError = true;
                if (!$("#form-certificat-fin-travaux").kendoValidator().data("kendoValidator").validate()) {
                    $(".k-invalid:first").focus();
                    NoError = false;
                }

                if (!$('#ClientPoseDevantClient1').is(':checked') && !$('#ClientPoseDevantClient2').is(':checked')) {
                    $('#ClientPoseDevantClient_validationMessage').removeClass("hidden");
                    if (NoError) {
                        $('#ClientPoseDevantClient_validationMessage').goTo("ClientPoseDevantClient_validationMessage");
                    }
                    NoError = false;
                }

                if (!$('#DetailProtectionChantier1').is(':checked') && !$('#DetailProtectionChantier2').is(':checked')) {
                    $('#DetailProtectionChantier_validationMessage').removeClass("hidden");
                    if (NoError) {
                        $('#DetailProtectionChantier_validationMessage').goTo("DetailProtectionChantier_validationMessage");
                    }
                    NoError = false;
                }

                if (!$('#DetailMontageInstallation1').is(':checked') && !$('#DetailMontageInstallation2').is(':checked')) {
                    $('#DetailMontageInstallation_validationMessage').removeClass("hidden");
                    if (NoError) {
                        $('#DetailMontageInstallation_validationMessage').goTo("DetailMontageInstallation_validationMessage");
                    }
                    NoError = false;
                }

                if (!$('#DetailMiseEnPlaceElectro1').is(':checked') && !$('#DetailMiseEnPlaceElectro2').is(':checked')) {
                    $('#DetailMiseEnPlaceElectro_validationMessage').removeClass("hidden");
                    if (NoError) {
                        $('#DetailMiseEnPlaceElectro_validationMessage').goTo("DetailMiseEnPlaceElectro_validationMessage");
                    }
                    NoError = false;
                }

                if (!$('#DetailRaccordement1').is(':checked') && !$('#DetailRaccordement2').is(':checked')) {
                    $('#DetailRaccordement_validationMessage').removeClass("hidden");
                    if (NoError) {
                        $('#DetailRaccordement_validationMessage').goTo("DetailRaccordement_validationMessage");
                    }
                    NoError = false;
                }

                if (!$('#DetailControleFonctionnement1').is(':checked') && !$('#DetailControleFonctionnement2').is(':checked')) {
                    $('#DetailControleFonctionnement_validationMessage').removeClass("hidden");
                    if (NoError) {
                        $('#DetailControleFonctionnement_validationMessage').goTo("DetailControleFonctionnement_validationMessage");
                    }
                    NoError = false;
                }

                if (!$('#DetailControleArriveesEvacuations1').is(':checked') && !$('#DetailControleArriveesEvacuations2').is(':checked')) {
                    $('#DetailControleArriveesEvacuations_validationMessage').removeClass("hidden");
                    if (NoError) {
                        $('#DetailControleArriveesEvacuations_validationMessage').goTo("DetailControleArriveesEvacuations_validationMessage");
                    }
                    NoError = false;
                }

                if (!$('#DetailControleDefautAspects1').is(':checked') && !$('#DetailControleDefautAspects2').is(':checked')) {
                    $('#DetailControleDefautAspects_validationMessage').removeClass("hidden");
                    if (NoError) {
                        $('#DetailControleDefautAspects_validationMessage').goTo("DetailControleDefautAspects_validationMessage");
                    }
                    NoError = false;
                }

                if (!$('#DetailNettoyageFinChantier1').is(':checked') && !$('#DetailNettoyageFinChantier2').is(':checked')) {
                    $('#DetailNettoyageFinChantier_validationMessage').removeClass("hidden");
                    if (NoError) {
                        $('#DetailNettoyageFinChantier_validationMessage').goTo("DetailNettoyageFinChantier_validationMessage");
                    }
                    NoError = false;
                }

                if (!$('#DetailSavPrevoir1').is(':checked') && !$('#DetailSavPrevoir2').is(':checked')) {
                    $('#DetailSavPrevoir_validationMessage').removeClass("hidden");
                    if (NoError) {
                        $('#DetailSavPrevoir_validationMessage').goTo("DetailSavPrevoir_validationMessage");
                    }
                    NoError = false;
                }

                if ($('#Solde1').is(':checked') && !$('#SoldePercuCeJour1').is(':checked') && !$('#SoldePercuCeJour2').is(':checked')) {
                    $('#SoldePercuCeJour_validationMessage').removeClass("hidden");
                    if (NoError) {
                        $('#SoldePercuCeJour_validationMessage').goTo("SoldePercuCeJour_validationMessage");
                    }
                    NoError = false;
                }

                if ($('#Solde1').is(':checked') && $('#SoldePercuCeJour1').is(':checked') && !$('#TypeReglementCheque').is(':checked') && !$('#TypeReglementEspece').is(':checked')) {
                    $('#TypeReglement_validationMessage').removeClass("hidden");
                    if (NoError) {
                        $('#TypeReglement_validationMessage').goTo("TypeReglement_validationMessage");
                    }
                    NoError = false;
                }

                if ($("#IsSigned").val() === "true" && !$('#ClientPoseDevantClient2').is(':checked')
                    && !$('#NotationNote1').is(':checked') && !$('#NotationNote2').is(':checked')
                    && !$('#NotationNote3').is(':checked') && !$('#NotationNote4').is(':checked')
                    && !$('#NotationNote5').is(':checked') && !$('#NotationNote6').is(':checked')
                    && !$('#NotationNote7').is(':checked') && !$('#NotationNote8').is(':checked')
                    && !$('#NotationNote9').is(':checked') && !$('#NotationNote10').is(':checked')) {
                    $('#NotationNote_validationMessage').removeClass("hidden");
                    if (NoError) {
                        $('#NotationNote_validationMessage').goTo("NotationNote_validationMessage");
                    }
                    NoError = false;
                }

                if (!$('#Reserves1').is(':checked') && !$('#Reserves2').is(':checked')) {
                    $('#Reserves_validationMessage').removeClass("hidden");
                    if (NoError) {
                        $('#Reserves_validationMessage').goTo("Reserves_validationMessage");
                    }
                    NoError = false;
                }

                if (!$('#Solde1').is(':checked') && !$('#Solde2').is(':checked')) {
                    $('#Solde_validationMessage').removeClass("hidden");
                    if (NoError) {
                        $('#Solde_validationMessage').goTo("Solde_validationMessage");
                    }
                    NoError = false;
                }

                if ($("#IsSigned").val() === "true" && !$('#SignatureMention').is(':checked')) {
                    $('#SignatureMention_validationMessage').removeClass("hidden");
                    if (NoError) {
                        $('#SignatureMention_validationMessage').goTo("SignatureMention_validationMessage");
                    }
                    NoError = false;
                }
                if ($.fn.DocumentsNumeriques.IsSigned()) {
                    $('#ImprimerDocumentsNumeriquesHaut').removeClass("disabled");
                    $('#EnvoyerClientFinalDocumentsNumeriquesHaut').removeClass("disabled");
                }

                $.fn.DocumentsNumeriques.InitPanelBar();
                $.fn.DocumentsNumeriques.RefreshPourcentageCompletion();

                if (!NoError) {
                    Swal.showLoading();
                }
                return NoError;
            },

            CertificatFinTravauxSuccess: function (data) {
                var status = "sucess";
                Swal.close();
                if (data.success === 1) {
                    $('#DocumentsNumeriquesID').val(data.documentsNumeriquesID);
                    toastr.success(Resource.SuccessMsgCertificatFinTravaux);
                    document.location.reload();
                }
                else if (data.success === 0) {
                    status = "fail";
                    toastr.error(data.message);
                }
                else {
                    status = "fail";
                    toastr.error(Resource.ErrorMsgCertificatFinTravaux);
                }

                if ($('#PointEntree').val() == "api") {
                    window.ReactNativeWebView.postMessage(status);
                }
            },

            CertificatFinTravauxError: function () {
                if ($('#PointEntree').val() == "api") {
                    window.ReactNativeWebView.postMessage("fail");
                }
                Swal.close();
                toastr.error(Resource.ErrorMsgCertificatFinTravaux);
            },

            ChampObligatoirePourSignature: function () {
                $("#NotationAppreciation").attr("data-val-required", Resource.ChampObligatoire);
                $.fn.CertificatFinTravaux.CertificatFinTravauxSuccessOnBegin();
                Swal.close();
            }
        };
        $.fn.CertificatFinTravaux.InitialisationCertificatFinTravaux(currentState.isNew);
    }
}