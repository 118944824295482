import Swal from "sweetalert2";

export default class inlineLabelEditor {

    constructor($view) {
        this.bind();
    }

    bind() {

        $.fn.inlineLabelEditor = {
            wipozProjetID: "",
            urlGetDocumentProjet: "",
            urlGetDocumentProjetOnEditPage: "",


            editNameDocument: function (idFichier, libelle) {
                if (!$.fn.inlineLabelEditor.IsWhitespaceOrEmpty(libelle)) {
                    $.ajax({
                        url: "/Fichier/Projet_DocumentRename?Wipoz_FichierId=" + idFichier + "&libelle=" + libelle.trim(),
                        success: function (data) {
                            Swal.fire("", ResourceDocument.DocumentModifierSuccess, "success");
                        },
                        error: function (data) {
                            Swal.fire("", ResourceDocument.ErreurSurvenueEditionDocument, "error");
                        }
                    })
                }
            },

            switchControls: function (controlToHide, controlToDisplay) {
                controlToHide.hide();
                controlToDisplay.show();
            },

            editNameDocumentOnSwitchControls: function (idFichier, libelle, controlToHide, controlToDisplay) {
                if (!$.fn.inlineLabelEditor.IsWhitespaceOrEmpty(libelle)) {
                    if (controlToDisplay.attr("libelle-value") != libelle) {
                        $.fn.inlineLabelEditor.editNameDocument(idFichier, libelle);
                        //Utilisation de attr car l'un des contrôles est de type lien (balise 'a')
                        controlToDisplay.attr("libelle-value", libelle);
                        controlToHide.attr("libelle-value", libelle);
                        $(controlToDisplay).find(".file-label").text(libelle)
                    }
                    $.fn.inlineLabelEditor.switchControls(controlToHide, controlToDisplay);
                }
            },

            triggerEditNameDocument: function (keyCode, idFichier, idTextBox, idLabel) {
                if (keyCode === 13) { // 13 = touche entrée
                    $.fn.inlineLabelEditor.editNameDocumentOnSwitchControls(idFichier, $('#' + idTextBox).val(),
                        $('#' + idTextBox), $('#' + idLabel));
                }
            },

            IsWhitespaceOrEmpty: function (text) {
                return !/[^\s]/.test(text);
            },

            
        };
    }
}