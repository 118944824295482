export default class Supplier {
    constructor($view) {
        this.view = $view;
        this.bind();
    }
    bind() {
        $.fn.Supplier = {
            add: function (e) {
                var grid = $("#GridSupplier").data("kendoGrid");
                grid.addRow();
            },
            onRequestEnd: function (e) {
                if (e.type == "create"
                    && e.response
                    && !e.response.Errors) {

                    $("#newSupplier").hide();
                    $("#newOrderNumber").show();
                    $("#addOrderNumber").removeClass("d-none");
                }
            },
            delete: function (e) {
                let dataItem = this.dataItem($(e.currentTarget).closest("tr"));

                Swal.fire({
                    title: AlertsActionsRsx.Title_SupprimerFournisseur,
                    text: AlertsActionsRsx.Message_Confirmation_SupprimerFournisseur,
                    type: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#2ABFBA',
                    cancelButtonColor: '#E0E0E0',
                    cancelButtonText: AlertsActionsRsx.Non,
                    confirmButtonText: AlertsActionsRsx.Oui,
                    showLoaderOnConfirm: () => !Swal.isLoading(),
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    preConfirm: () => {
                        $.ajax({
                            url: urlFournisseurActions.actionSupprimerFournisseur,
                            method: "POST",
                            data: {
                                id: dataItem.Wipoz_SupplierID,
                            },
                            success: function (data) {
                                Swal.close();
                                if (data.success == 1) {
                                    toastr.success(data.message);
                                    $('#GridSupplier').data('kendoGrid').dataSource.remove(dataItem);
                                    $('#GridSupplier').data('kendoGrid').dataSource.sync();
                                }
                                else {
                                    toastr.error(data.message);
                                }
                            },
                            error: function (e) {
                                Swal.close();
                                console.log(e);
                            }
                        });
                    }
                }).then(() => {
                });
            },
            isEditable: function (e) {
                return !e.HasOrderNumber;
            },
            isActionVisible: function (e) {
                return !e.HasOrderNumber;
            },
        }
    }
}