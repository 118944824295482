export default class Discussion {
    constructor($view) {
        this.view = $view;
        this.bind();
    }
    bind() {
        var $view = $(this.view);
        $.fn.Discussion = {
            AddComment: function (type) {
                var message = $("#message").data("kendoEditor").value();
                if (message == '') {
                    toastr.error($view.data("nomessage"));
                    return;
                }

                var formData = new FormData();
                var fichiers = $("#file").data("kendoUpload").getFiles();
                if (fichiers.length > 0) {
                    formData.append("fichier", fichiers[0].rawFile);
                }
                formData.append("projetId", $("#Wipoz_ProjetID").val());
                formData.append("message", $("#message").data("kendoEditor").value());
                formData.append("MessageExpediteur", type);

                $.ajax({
                    url: $view.data("url"),
                    type: 'POST',
                    processData: false,
                    contentType: false,
                    data: formData,
                    success: function (datas) {
                        if (datas.success === "ok") {
                            toastr.success($view.data("mailsuccess"));
                            $(".commentList").append(datas.content);
                            $(".commentList").scrollTop($(".commentList .sl-item:last-child").position().top)
                            $("#message").data("kendoEditor").value("");
                            var upload = $("#file").data("kendoUpload");
                            upload.clearAllFiles();
                        }
                        else if (datas.success === "bigfile") {
                            toastr.error(datas.message);
                        }
                        else {
                            toastr.error($view.data("mailerror"));
                        }
                    },
                    error: function () {
                        toastr.error($view.data("mailerror"));
                    }
                });
            },
            serialization: function (html) {
                var turndownService = new TurndownService();
                return turndownService.turndown(html);
            },
        }
    }
}