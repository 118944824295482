export default class ProjectList {
    constructor($view) {
        this.view = $view;
        this.bind($view);
    }

    bind($view) {
        this.view = $($view);

        window.onChange = function() {
            var model = this.dataItem(this.select());
            var id = model.Wipoz_ProjetID;

            var url = "/Projet/Fiche?Wipoz_ProjetID=" + id + "&type=" + $("#project-list-container").data("typeProjet");
            window.location.href = url;
        }

        window.dataBound = function(e) {
            var grid = $("#Grid").data("kendoGrid");
            var gridData = grid.dataSource.view();

            for (var i = 0; i < gridData.length; i++) {
                console.log(gridData[i]);
                if (gridData[i].Wipoz_EtatAvancementDemandeEnCours == true) {
                    grid.table.find("tr[data-uid='" + gridData[i].uid + "']").find("td").last().addClass("state-demandinprogress");
                }
                else if (gridData[i].Wipoz_EtatAvancementEnCours == true) {
                    grid.table.find("tr[data-uid='" + gridData[i].uid + "']").find("td").last().addClass("state-inprogress");
                }
                else if (gridData[i].Wipoz_EtatAvancementAbandon == true) {
                    grid.table.find("tr[data-uid='" + gridData[i].uid + "']").find("td").last().addClass("state-disused");
                }
                else {
                    grid.table.find("tr[data-uid='" + gridData[i].uid + "']").find("td").last().addClass("state-finished");
                }
            }
        }
    }
}