import Swal from "sweetalert2";

export default class createOrEditDocument {

    constructor($view) {
        this.view = $view;
        this.bind();
    }

    bind() {
        $.fn.createOrEdit.activateNext();

        $("#form-projet").kendoValidator({
            validate: function (ev) {
                $(".k-invalid:first").focus();
                if ($(".k-invalid:first").data("kendoNumericTextBox")) {
                    $(".k-invalid:first").data("kendoNumericTextBox").focus();
                }
            },
            rules: {
                range: function (input) {
                    if ($(input).is("[data-role=SoldeValue]")
                        && $(input).attr('data-val-required')) {

                        var min = parseFloat($(input).data("valRangeMin"), 2);
                        var value = parseFloat($(input).val());

                        if (isNaN(min)) {
                            return true;
                        }
                        if (isNaN(value)) {
                            return false;
                        }
                        // La valeur min n'est pas autorisée
                        return min < value;
                    }
                    return true;
                }
            },
            messages: {
                range: function (input) {
                    var value = parseFloat($(input).val());
                    if (isNaN(value)) {
                        return $(input).data("valNumber");
                    }
                    else {
                        return $(input).data("isrequiredsolde");
                    }
                }
            }
        });

        $.fn.createOrEditDocument = {
            wipozProjetID: "",
            urlGetDocumentProjet: "",
            urlGetDocumentProjetOnEditPage: "",
            initialize: function () {
                $.fn.createOrEditDocument.wipozProjetID = $("#Wipoz_ProjetID").val();
                $.fn.createOrEditDocument.urlGetDocumentProjet = CreateOrEditURLs.GetDocumentProjet;
                $.fn.createOrEditDocument.urlGetDocumentProjetOnEditPage = CreateOrEditURLs.GetDocumentProjetOnEditPage;
            },

            onFileSuccess: function (e) {
                if (e.response.success == 1) {
                    $('#FileName').val(e.response.filename);
                }
            },

            onFileProjetRemove: function (e) {
            },

            onFileUpload: function () {
                $('#FileName').val("")
            },

            loadDocument: function (isEditPage, typeDocumentId) {
                if (typeDocumentId === undefined) {
                    return;
                }

                return $.ajax({
                    url: isEditPage ? $.fn.createOrEditDocument.urlGetDocumentProjetOnEditPage : $.fn.createOrEditDocument.urlGetDocumentProjet,
                    method: "GET",
                    async: false, // Mode synchrone
                    data: { Wipoz_ProjetID: $("#Wipoz_ProjetID").val(), TypeDocumentID: typeDocumentId },
                    success: function (data) {
                        $(".js-document-" + typeDocumentId).html(data);
                        window.Kernel.mountComponents($(".js-document-" + typeDocumentId));
                        $("#Modal").modal("hide");
                    }
                });
            },

            onSuccessFormDoc: function (data) {
                if (data.success === 1) {
                    $.fn.createOrEditDocument.loadDocument();
                    toastr.success(ResourceDocument.DocumentAjouterSuccess);
                }
            },

            onFailureFormDoc: function () {
                Swal.close();
            },

            downloadComplete: function () {
                if ($('#FileName').val() !== "") {
                    // validation formulaire se poursuit
                } else {
                    toastr.error(ResourceDocument.DocumentNonChargeOuSelectionne);
                    return false;
                }
            },

            deleteDocument: function (idFichier, nom, isOnEditPage, typeDocumentId) {
                Swal.fire({
                    title: ResourceDocument.SuppressionDocument,
                    text: ResourceDocument.VoulezVousSupprimerDocument + nom + " ?",
                    type: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#2ABEBA',
                    cancelButtonText: ResourceDocument.Non,
                    confirmButtonText: ResourceDocument.Oui,
                    showLoaderOnConfirm: true,
                    preConfirm: () => {
                        return fetch(DocumentActions.DeleteDocument + idFichier, {
                            method: 'GET',
                            credentials: 'include'
                        })
                            .then(response => {
                                if (!response.ok) {
                                    throw new Error(response.statusText)
                                }
                                $.fn.createOrEditDocument.loadDocument(isOnEditPage, typeDocumentId);
                                toastr.success(ResourceDocument.DocumentSupprimeSuccess);

                                return true;
                            })
                            .catch(error => {
                                Swal.showValidationMessage(
                                    ResourceDocument.ImpossibleSupprimerDocument
                                );
                            });
                    },
                    allowOutsideClick: () => !Swal.isLoading()
                }).then((result) => {
                });
                return false;
            },

            editNameDocument: function (idFichier, libelle) {
                if (!$.fn.createOrEditDocument.IsWhitespaceOrEmpty(libelle)) {
                    $.ajax({
                        url: "/Fichier/Projet_DocumentRename?Wipoz_FichierId=" + idFichier + "&libelle=" + libelle.trim(),
                        success: function (data) {
                            Swal.fire("", ResourceDocument.DocumentModifierSuccess, "success");
                        },
                        error: function (data) {
                            Swal.fire("", ResourceDocument.ErreurSurvenueEditionDocument, "error");
                        }
                    })
                }
            },
            switchControls: function (controlToHide, controlToDisplay) {
                controlToHide.hide();
                controlToDisplay.show();
            },

            editNameDocumentOnSwitchControls: function (idFichier, libelle, controlToHide, controlToDisplay) {
                if (!$.fn.createOrEditDocument.IsWhitespaceOrEmpty(libelle)) {
                    if (controlToDisplay.attr("libelle-value") != libelle) {
                        $.fn.createOrEditDocument.editNameDocument(idFichier, libelle);
                        //Utilisation de attr car l'un des contrôles est de type lien (balise 'a')
                        controlToDisplay.attr("libelle-value", libelle);
                        controlToHide.attr("libelle-value", libelle);
                        $(controlToDisplay).find(".file-label").text(libelle)
                    }
                    $.fn.createOrEditDocument.switchControls(controlToHide, controlToDisplay);
                }
            },

            triggerEditNameDocument: function (keyCode, idFichier, idTextBox, idLabel) {
                if (keyCode === 13) { // 13 = touche entrée
                    $.fn.createOrEditDocument.editNameDocumentOnSwitchControls(idFichier, $('#' + idTextBox).val(),
                        $('#' + idTextBox), $('#' + idLabel));
                }
            },

            published: false,

            OnBeginEtapeDocument: function () {
                $.fn.createOrEdit.ShowLoadingCreateProjet();
                if (!$.fn.createOrEditDocument.published) {

                    if ($('#UploadImageProjet').val() == "") {
                        toastr.error(ResourceDocument.ImageNonTotalementChargee);
                        return false;
                    }
                }
                else {
                    $.fn.createOrEditDocument.published = false;
                }
            },

            IsWhitespaceOrEmpty: function (text) {
                return !/[^\s]/.test(text);
            },

            
        };
        $.fn.createOrEditDocument.initialize();

        window.withPublish = false;
        window.confirmAndPublish = function () {
            withPublish = true;
            $("#form-projet").submit();
        }
        window.confirmMeasurement = function () {
            $("#IsEnregistrer").val("False");
            $("#form-projet").submit();
        }
    }
}