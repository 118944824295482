export default class Extensions {
    constructor($view) {
        this.view = $view;
        this.bind($view);
    }

    bind($view) {
        this.view = $($view);


        // Extensions Jquery
        (function ($) {
            window.externalFilters = function () {
                var data = {};
                $.each($('.externalSearch'), function (i, item) {
                    data[$(item).data('search')] = $(item).val();
                });
                return data;
            }

            var lastStringSearch = [];

            $.fn.externalStringSearch = function () {
                var searchInput = $(this);
                searchInput.focus();
                searchInput.addClass('searchi');
                searchInput.keyup(function () {
                    var item = this;
                    if (checkMinimum(item.value) && item.value != lastStringSearch[searchInput.id]) {
                        delay(function () {
                            lastStringSearch[searchInput.id] = item.value;
                            callRead();
                        }, 700);
                    }
                });
                searchInput.bind('paste', function () {
                    if (checkMinimum(this.value))
                        callRead();
                });
            }

            $.fn.stringSearch = function () {
                var searchInput = $(this);
                searchInput.focus();
                searchInput.addClass('searchi');
                searchInput.keyup(function () {
                    var item = this;
                    if (checkMinimum(item.value) && item.value != lastStringSearch[searchInput.id]) {
                        delay(function () {
                            lastStringSearch[searchInput.id] = item.value;
                            applyStringFilter(item);
                        }, 700);
                    }
                });
                searchInput.bind('paste', function () {
                    if (checkMinimum(this.value))
                        applyStringFilter(this);
                });
            }

            $.fn.numericSearch = function () {
                var searchInput = $(this);
                searchInput.focus();
                searchInput.addClass('searchi');
                searchInput.change(function () {
                    applyNumericFilter(this);
                });
            }

            $.fn.dateSearch = function (filterType) {
                var searchInput = $(this);
                searchInput.focus();
                searchInput.addClass('searchi');
                searchInput.keyup(function () {
                    if (this.value == '' || isDate(this.value))
                        applyDateFilter(this, filterType);
                });
            }

            $.fn.checkSearch = function () {
                var searchInput = $(this);
                searchInput.focus();
                searchInput.addClass('searchi');
                searchInput.click(function () {
                    applyCheckFilter(this);
                });
            }

            $.fn.selectSearch = function () {
                $(this).change(function () {
                    applyDropDownListFilter(this);
                });
            }
        })(jQuery);

        var subfilter = { logic: 'or', filters: [] };

        window.callRead = function () {
            var gridData = $('#Grid').data('kendoGrid');
            gridData.dataSource.read();
        }

        window.getDefaultValues = function () {
            //var filter = null;
            $.each($('[default-criteria]'), function (i, value) {
                var item = $(value);

                if (item.hasClass('externalSearch'))
                    item.keyup();
                else if (item.hasClass('stringSearch'))
                    item.keyup();
                else if (item.hasClass('numericSearch'))
                    item.keyup();
                else if (item.hasClass('dateSearch'))
                    item.keyup();
                else if (item.hasClass('checkSearch'))
                    item.click();
                else if (item.hasClass('selectSearch'))
                    item.change();
            });
            //if (filter != null) {
            //    callRead();
            //}
        }

        window.applyFilter = function (filterField, filterValue, filterOperator, logic) {//, isDeferredRequest){
            var gridData = $('#Grid').data('kendoGrid');
            var currFilterObj = gridData.dataSource.filter();
            var currentFilters;
            currentFilters = currFilterObj ? currFilterObj.filters : [];
            if (currentFilters && currentFilters.length > 0) {
                for (var i = 0; i < currentFilters.length; i++) {
                    if (currentFilters[i].field == filterField && currentFilters[i].operator == filterOperator) {
                        currentFilters.splice(i, 1);
                        break;
                    }
                }
            }
            i++;

            if (filterValue != 'NONE')
                currentFilters.push({ field: filterField, operator: filterOperator, value: filterValue });

            //if (isDeferredRequest)
            //    return { logic: logic, filters: currentFilters };
            //else
            gridData.dataSource.filter({ logic: logic, filters: currentFilters });
        }

        window.toggleSubFilter = function (filterField) {
            var gridData = $('#Grid').data('kendoGrid');
            var currFilterObj = gridData.dataSource.filter();
            var currentFilters;
            currentFilters = currFilterObj ? currFilterObj.filters : [];

            if (subfilter && subfilter.filters.length > 0 && $.inArray(subfilter, currentFilters) == -1)
                currentFilters.push(subfilter);
            else {
                $.each(currentFilters, function (index, item) {
                    if (item !== undefined) {
                        if (item.filters !== undefined // Le filtre est "subfilter" ET
                            && (item.filters.length == 0 // Le filtre ne contiens plus rien, on le retire OU
                                || (item.filters.length > 0  // Le filtre ne contiens la valeur, on le retire
                                    && item.filters[0].field == filterField)))
                            currentFilters.splice(index, 1);
                    }
                });
                // Replace
                if (subfilter && subfilter.filters.length > 0)
                    currentFilters.push(subfilter);
            }
            gridData.dataSource.filter({ logic: 'and', filters: currentFilters });
        }

        window.normalize = (function () {
            var a = ['À', 'Á', 'Â', 'Ã', 'Ä', 'Å', 'Æ', 'Ç', 'È', 'É', 'Ê', 'Ë', 'Ì', 'Í', 'Î', 'Ï', 'Ð', 'Ñ', 'Ò', 'Ó', 'Ô', 'Õ', 'Ö', 'Ø', 'Ù', 'Ú', 'Û', 'Ü', 'Ý', 'ß', 'à', 'á', 'â', 'ã', 'ä', 'å', 'æ', 'ç', 'è', 'é', 'ê', 'ë', 'ì', 'í', 'î', 'ï', 'ñ', 'ò', 'ó', 'ô', 'õ', 'ö', 'ø', 'ù', 'ú', 'û', 'ü', 'ý', 'ÿ', 'Ā', 'ā', 'Ă', 'ă', 'Ą', 'ą', 'Ć', 'ć', 'Ĉ', 'ĉ', 'Ċ', 'ċ', 'Č', 'č', 'Ď', 'ď', 'Đ', 'đ', 'Ē', 'ē', 'Ĕ', 'ĕ', 'Ė', 'ė', 'Ę', 'ę', 'Ě', 'ě', 'Ĝ', 'ĝ', 'Ğ', 'ğ', 'Ġ', 'ġ', 'Ģ', 'ģ', 'Ĥ', 'ĥ', 'Ħ', 'ħ', 'Ĩ', 'ĩ', 'Ī', 'ī', 'Ĭ', 'ĭ', 'Į', 'į', 'İ', 'ı', 'Ĳ', 'ĳ', 'Ĵ', 'ĵ', 'Ķ', 'ķ', 'Ĺ', 'ĺ', 'Ļ', 'ļ', 'Ľ', 'ľ', 'Ŀ', 'ŀ', 'Ł', 'ł', 'Ń', 'ń', 'Ņ', 'ņ', 'Ň', 'ň', 'ŉ', 'Ō', 'ō', 'Ŏ', 'ŏ', 'Ő', 'ő', 'Œ', 'œ', 'Ŕ', 'ŕ', 'Ŗ', 'ŗ', 'Ř', 'ř', 'Ś', 'ś', 'Ŝ', 'ŝ', 'Ş', 'ş', 'Š', 'š', 'Ţ', 'ţ', 'Ť', 'ť', 'Ŧ', 'ŧ', 'Ũ', 'ũ', 'Ū', 'ū', 'Ŭ', 'ŭ', 'Ů', 'ů', 'Ű', 'ű', 'Ų', 'ų', 'Ŵ', 'ŵ', 'Ŷ', 'ŷ', 'Ÿ', 'Ź', 'ź', 'Ż', 'ż', 'Ž', 'ž', 'ſ', 'ƒ', 'Ơ', 'ơ', 'Ư', 'ư', 'Ǎ', 'ǎ', 'Ǐ', 'ǐ', 'Ǒ', 'ǒ', 'Ǔ', 'ǔ', 'Ǖ', 'ǖ', 'Ǘ', 'ǘ', 'Ǚ', 'ǚ', 'Ǜ', 'ǜ', 'Ǻ', 'ǻ', 'Ǽ', 'ǽ', 'Ǿ', 'ǿ'];
            var b = ['A', 'A', 'A', 'A', 'A', 'A', 'AE', 'C', 'E', 'E', 'E', 'E', 'I', 'I', 'I', 'I', 'D', 'N', 'O', 'O', 'O', 'O', 'O', 'O', 'U', 'U', 'U', 'U', 'Y', 's', 'a', 'a', 'a', 'a', 'a', 'a', 'ae', 'c', 'e', 'e', 'e', 'e', 'i', 'i', 'i', 'i', 'n', 'o', 'o', 'o', 'o', 'o', 'o', 'u', 'u', 'u', 'u', 'y', 'y', 'A', 'a', 'A', 'a', 'A', 'a', 'C', 'c', 'C', 'c', 'C', 'c', 'C', 'c', 'D', 'd', 'D', 'd', 'E', 'e', 'E', 'e', 'E', 'e', 'E', 'e', 'E', 'e', 'G', 'g', 'G', 'g', 'G', 'g', 'G', 'g', 'H', 'h', 'H', 'h', 'I', 'i', 'I', 'i', 'I', 'i', 'I', 'i', 'I', 'i', 'IJ', 'ij', 'J', 'j', 'K', 'k', 'L', 'l', 'L', 'l', 'L', 'l', 'L', 'l', 'l', 'l', 'N', 'n', 'N', 'n', 'N', 'n', 'n', 'O', 'o', 'O', 'o', 'O', 'o', 'OE', 'oe', 'R', 'r', 'R', 'r', 'R', 'r', 'S', 's', 'S', 's', 'S', 's', 'S', 's', 'T', 't', 'T', 't', 'T', 't', 'U', 'u', 'U', 'u', 'U', 'u', 'U', 'u', 'U', 'u', 'U', 'u', 'W', 'w', 'Y', 'y', 'Y', 'Z', 'z', 'Z', 'z', 'Z', 'z', 's', 'f', 'O', 'o', 'U', 'u', 'A', 'a', 'I', 'i', 'O', 'o', 'U', 'u', 'U', 'u', 'U', 'u', 'U', 'u', 'U', 'u', 'A', 'a', 'AE', 'ae', 'O', 'o'];

            return function (str) {
                var i = a.length;
                while (i--) str = str.replace(a[i], b[i]);
                return str;
            };
        }());

        window.applyStringFilter = function (item) {
            var searchCriteria = $(item).data('search');
            var q = normalize($(item).val());
            if (q != '')
                applyFilter(searchCriteria, q, 'contains', 'and');
            else
                applyFilter(searchCriteria, 'NONE', 'contains', 'and');
        }

        window.applyNumericFilter = function (item) {
            var searchCriteria = $(item).data('search');
            var q = $(item).val();
            if (q != '')
                applyFilter(searchCriteria, q, 'eq', 'and');
            else
                applyFilter(searchCriteria, 'NONE', 'eq', 'and');
        }

        window.applyDateFilter = function (item, filterType) {
            var searchCriteria = $(item).data('search');
            var q = $(item).val();
            if (q != '')
                if (filterType == "lte")
                    applyFilter(searchCriteria, formatDate(q) + " 11:59:59 PM", filterType, 'and');
                else if (filterType == "gte")
                    applyFilter(searchCriteria, formatDate(q) + " 00:00:00 AM", filterType, 'and');
                else
                    applyFilter(searchCriteria, formatDate(q), filterType, 'and');
            else
                applyFilter(searchCriteria, 'NONE', filterType, 'and');
        }

        window.applyCheckFilter = function (item, filterType) {
            var searchCriteria = $(item).data('search');
            var values = $(item).val().split('|');
            if ($(item).is(':checked')) {
                $.each(values, function (index, value) {
                    subfilter.filters.push({ field: searchCriteria, operator: 'eq', value: value });
                });
            }
            else {
                $.each(values, function (index, value) {
                    for (var i = 0; i < subfilter.filters.length; i++) {
                        if (subfilter.filters[i].value == value) {
                            subfilter.filters.splice(i, 1);
                            break;
                        }
                    }
                });
            }
            toggleSubFilter(searchCriteria);
        }

        window.applyDropDownListFilter = function (item) {
            var searchCriteria = $(item).data('search');
            var q = $(item).val();
            if (q != '')
                applyFilter(searchCriteria, q, 'eq', 'and');
            else
                applyFilter(searchCriteria, 'NONE', 'eq', 'and');
        }

        window.isDate = function (value) {
            var date_regex = /^(0[1-9]|1\d|2\d|3[01])\/(0[1-9]|1[0-2])\/(19|20)\d{2}$/;
            return date_regex.test(value);
        }

        window.formatDate = function (value) {
            var dateItem = value.split('/');
            return dateItem[1] + '/' + dateItem[0] + '/' + dateItem[2];
        }

        window.checkMinimum = function (value) {
            return (value.length == 0 || value.length >= 2);
        }

        window.delay = (function () {
            var timer = 0;
            return function (callback, ms) {
                clearTimeout(timer);
                timer = setTimeout(callback, ms);
            };
        })();

        $(function () {
            setTimeout(function () {
                getDefaultValues();
            }, 100);
        });


        (function ($) {
            $.fn.OrderableGrid = function (options) {
                var defaults = {
                    url: "Order"
                };

                var settings = $.extend(defaults, options);

                return $(this).each(function (e) {
                    $(this).addClass("ec-grid-orderable");
                    $(this).attr("data-orderable-change-url", settings.url);
                });
            }
        }(jQuery));

        var noHint = $.noop;
        window.ecOrderable = {
            placeholder: function (element) {
                return element.clone().addClass("k-state-hover").css("opacity", 0.65);
            },
            onChange: function (e) {
                var divGrid = $(e.item[0]).closest(".ec-grid-orderable");
                var grid = $(divGrid).data("kendoGrid"),
                    skip = grid.dataSource.skip(),
                    oldIndex = e.oldIndex + skip,
                    newIndex = e.newIndex + skip,
                    data = grid.dataSource.data(),
                    dataItem = grid.dataSource.getByUid(e.item.data("uid"));

                grid.dataSource.remove(dataItem);
                grid.dataSource.insert(newIndex, dataItem);

                var datas = JSON.stringify({ viewModel: dataItem, oldOrder: oldIndex + 1, newOrder: newIndex + 1 }, null, 2);

                jQuery.ajax({
                    url: $(divGrid).data("orderable-change-url"),
                    type: "POST",
                    dataType: "json",
                    contentType: "application/json; charset=utf-8",
                    data: datas,
                    success: function (response) {
                        grid.dataSource.read(); //refresh the Grid
                    },
                    error: function () {
                        alert('Error');
                    },
                    complete: function () {
                        grid.dataSource.cancelChanges();
                    }
                });
            }
        }

        window.ecGridCommand = {
            createEntity: function (e, url) {
                e.preventDefault();
                document.location = url;
            },
            editEntity: function (e, baseUrl, isSpecificUrl) {
                e.preventDefault();
                var row = $(e.target).closest("tr");
                var grid = $(row).closest(".k-grid").data("kendoGrid");
                var dataItem = grid.dataItem(row);
                document.location = isSpecificUrl ? baseUrl + '&id=' + dataItem.id : baseUrl + "/" + dataItem.id;
            },
            destroyConfirmEntity: function (e) {
                ecAlert.confirm("Suppression ?", "Etes-vous sûr de vouloir supprimer cet item ?", function () {
                    ecGridCommand.destroyEntity(e);
                });
            },
            destroyEntity: function (e) {
                e.preventDefault();
                var row = $(e.target).closest("tr");
                var grid = $(row).closest(".k-grid").data("kendoGrid");
                var dataItem = grid.dataItem(row);
                grid.dataSource.remove(dataItem);  //prepare a "destroy" request 
                grid.dataSource.sync(dataItem);
            },
            entityActivation: function (e, url, activationState) {
                e.preventDefault();
                var row = $(e.target).closest("tr");
                var grid = $(row).closest(".k-grid").data("kendoGrid");
                var dataItem = grid.dataItem(row);
                //console.log(dataItem);

                var data = {
                    id: dataItem.id,
                    activationState: activationState
                }
                $.ajax({
                    url: url,
                    method: "POST",
                    data: data
                }).done(function () {
                    var action = (activationState) ? "réactivé" : "désactivé";
                    ecAlert.success("L'élément a bien été " + action, 2000);
                    $(row).find('.js-action-activate').toggleClass('hidden');
                    var checkState = $(row).find('.js-state-Actif');
                    checkState.prop('checked', !checkState.is(':checked'));
                })
                    .fail(function () {
                        ecAlert.error("Une erreur est survenue.");
                    })
            }
        };
    }
}