export default class createOrEditInfoGenerales {

    constructor($view) {
        this.view = $view;
        this.bind();
    }

    bind() {
        $("#form-projet").kendoValidator({
            validate: function (ev) {
                $(".k-invalid:first").focus();
            }
        });

        $.fn.createOrEdit.activateNext();

        $(document).ready(function () {
            $.fn.createOrEditInfoGenerales.OnChangeProjectType();

            $("input[name=Wipoz_TypeProjetID]").click(function (e) {
                $('#TypeProjet_validationMessage').addClass("hidden");
                $.fn.createOrEditInfoGenerales.OnChangeProjectType();
            })

            $('#Ascenceur1').on('click', () => {
                $('#Ascenceur_validationMessage').addClass("hidden");
            });

            $('#Ascenceur2').on('click', () => {
                $('#Ascenceur_validationMessage').addClass("hidden");
            });

            $('#LogementPlusDe2Ans1').on('click', () => {
                $('#LogementPlusDe2Ans_validationMessage').addClass("hidden");
            });

            $('#LogementPlusDe2Ans2').on('click', () => {
                $('#LogementPlusDe2Ans_validationMessage').addClass("hidden");
            });

            $("#form-projet").kendoValidator({
                rules: {
                    conditionalRequired: function (input) {
                        // Vérifiez si l'input a l'attribut data-conditional-required
                        if (input.is("[data-conditional-required-isnotnullorempty]")) {
                            var condition = $("#" + input.data("conditionalRequiredIsnotnullorempty")).val();
                            var value = input.val();

                            return !(condition == '' && value == '');
                        }
                        return true;
                    }
                },
                messages: {
                    conditionalRequired: function (input) {
                        return input.data("conditionalRequiredIsnotnulloremptyMessage");
                    }
                }
            });
        });

        $.fn.createOrEditInfoGenerales = {
            OnChangeProjectType: function () {
                if ($('input[name=Wipoz_TypeProjetID]:checked').val() == typeProjet.Autre) {
                    $('#LibelleTypeProjetAutreDiv').show();
                }
                else {
                    $('#LibelleTypeProjetAutreDiv').hide();
                    $('#LibelleTypeProjetAutre').val("");
                }
            },

            OnBeginEtapeInfoGenerale: function () {
                var NoError = true;
                if ($('input[name=Wipoz_TypeProjetID]:checked').val() == null) {
                    $('#TypeProjet_validationMessage').removeClass("hidden");
                    NoError = false;
                }

                if (!$('#Ascenceur1').is(':checked') && !$('#Ascenceur2').is(':checked')) {
                    $('#Ascenceur_validationMessage').removeClass("hidden");
                    NoError = false;
                }

                if (!$('#LogementPlusDe2Ans1').is(':checked') && !$('#LogementPlusDe2Ans2').is(':checked')) {
                    $('#LogementPlusDe2Ans_validationMessage').removeClass("hidden");
                    NoError = false;
                }
                if (!NoError) {
                    Swal.close();
                }
                return NoError;
            }
        };
    }
}