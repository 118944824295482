export default class createOrEditManagement {

    constructor($view) {
        this.view = $view;
        this.bind();
    }

    bind() {
        var $view = $(this.view);

        $.fn.createOrEdit.activateNext();

        $("#form-projet").kendoValidator({
            validate: function (ev) {
                $(".k-invalid:first").focus();
            }
        });


        $(document).ready(function () {
            $("input[name=ManagementType]").click(function (e) {
                $('#ManagementType_validationMessage').addClass("hidden");
            })
        });

        $.fn.createOrEditManagement = {
            OnBeginEtapeManagement: function () {
                var NoError = true;
                if ($('input[name=ManagementType]:checked').val() == null
                    || $('input[name=ManagementType]:checked').val() == "") {
                    $('#ManagementType_validationMessage').removeClass("hidden");
                    NoError = false;
                }
                if (!NoError) {
                    Swal.close();
                }
                return NoError;
            },
            Submit: function () {
                var selected = $.fn.createOrEditManagement.GetSelectedNature();
                var actual = $.fn.createOrEditManagement.GetActualNature();
                if (actual == '' || actual == selected) {
                    $.fn.createOrEdit.ShowLoadingCreateProjet();
                    $("#form-projet").submit();
                }
                else {
                    Swal.fire({
                        title: ResourceCreationProjet.NatureChangeTitre,
                        html: ResourceCreationProjet.NatureChangeTexte,
                        type: "warning",
                        confirmButtonColor: "#2ABFBA",
                        confirmButtonText: ResourceCreationProjet.NatureActionTexte,
                        showCancelButton: true,
                        allowOutsideClick: false,
                    })
                        .then((r) => {
                            if (r.value != undefined
                                && r.value == true) {
                                Swal.close();
                                // nécessaire pour laisser le temps au swal de se fermer
                                setTimeout(function () {
                                    $.fn.createOrEdit.ShowLoadingCreateProjet();
                                    $("#form-projet").submit();
                                }, 250);
                            }
                            else {
                                return;
                            }
                        });
                }
            },
            GetSelectedNature: function () {
                return $("input[name=\"ManagementType\"]:checked").val();
            },
            GetActualNature: function () {
                return $view.data("management-type");
            },
        };
    }
}