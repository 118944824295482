export default class Attribution {
    constructor($view) {
        this.view = $view;
        this.bind();
    }

    bind() {
        
        $.fn.attribution = {

            selectCollaborateur: function (idCollaborateur) {
                $.fn.attribution.updateProject(idCollaborateur);
            },

            updateProject: function (idCollaborateur) {

                $.ajax({
                    url: "/Projet/AttribuerProjet",
                    method: "POST",
                    data: { Wipoz_ProjetID: $("#Wipoz_ProjetID").val(), idCollaborateur: idCollaborateur },
                    success: function () {
                        if ($.fn.attribution.hasValue(Resource.AttributionCollaborateurSuccess))
                        {
                            toastr.success(Resource.AttributionCollaborateurSuccess);
                        }
                        else if ($.fn.attribution.hasValue(Resource.ReattributionChantierSuccess))
                        {
                            toastr.success(Resource.ReattributionChantierSuccess);
                        }
                        location.reload();
                    },
                    error: function () {
                        if ($.fn.attribution.hasValue(Resource.AttributionCollaborateurError))
                        {
                            toastr.error(Resource.AttributionCollaborateurError);
                        }
                        else if ($.fn.attribution.hasValue(Resource.ReattributionChantierError))
                        {
                            toastr.error(Resource.ReattributionChantierError);
                        }
                    }
                });
            },

            //TOREFACTOR : HML - 2020/02/13 - Sortir cette fonction dans un js helper global
            hasValue: function (value) {
                return (value !== undefined && value !== null && value !== "");
            }
        }
    };
}