export default class AddressAutoComplete {
    constructor($view) {
        this.view = $view;
        this.bind($view);
    }

    bind($view) {
        this.view = $($view);

        $(document).ready(function () {
            $("#autoCompleteApiAddress").kendoAutoComplete({
                minLength: 5,
                dataTextField: "street",
                template: kendo.template($("#template").html()),
                ignoreCase: true,
                filter: "contains",
                dataSource: new kendo.data.DataSource({
                    transport: {
                        read: function (options) {
                            var value;
                            if ($("#autoCompleteApiAddress").data("kendoAutoComplete") === undefined) {
                                options.success([]);
                                return;
                            }
                            else {
                                value = $("#autoCompleteApiAddress").data("kendoAutoComplete").value();
                                if (value === '' || value === undefined || value === null) {
                                    options.success([]);
                                    return;
                                }
                            }

                            $.ajax({
                                url: UrlAddressAutoComplete.ApiAdresseGouv + value,
                                type: "GET",
                                dataType: 'json',
                                success: function (data) {
                                    options.success($.map(data.features, function (item) {
                                        return window.addressFormatter(item);
                                    }));
                                    return;
                                },
                                error: function () {
                                    options.error([]);
                                    return;
                                }
                            });
                        }
                    },
                    serverFiltering: true
                }),
                change: function () {
                    this.dataSource.read();
                },
                select: function (e) {
                    var item = e.dataItem;
                    $('.js-api-zipcode').val(item.postcode);
                    $('.js-api-city').val(item.city);
                }
            });

            $("#autoCompleteApiZipcode").kendoAutoComplete({
                minLength: 5,
                dataTextField: "postcode",
                template: kendo.template($("#template").html()),
                ignoreCase: true,
                filter: "contains",
                dataSource: new kendo.data.DataSource({
                    transport: {
                        read: function (options) {
                            var value;
                            if ($("#autoCompleteApiZipcode").data("kendoAutoComplete") === undefined) {
                                options.success([]);
                                return;
                            }
                            else {
                                value = $("#autoCompleteApiZipcode").data("kendoAutoComplete").value();
                                if (value === '' || value === undefined || value === null) {
                                    options.success([]);
                                    return;
                                }   
                            }

                            $.ajax({
                                url: UrlAddressAutoComplete.ApiAdresseGouv + value,
                                type: "GET",
                                dataType: 'json',
                                success: function (data) {
                                    options.success($.map(data.features, function (item) {
                                        return window.zipCodeFormatter(item);
                                    }));
                                    return;
                                },
                                error: function () {
                                    options.error([]);
                                    return;
                                }
                            });
                        }
                    },
                    serverFiltering: true
                }),
                change: function () {
                    this.dataSource.read();
                },
                select: function (e) {
                    var item = e.dataItem;
                    $('.js-api-city').val(item.city);
                }
            });

            $("#autoCompleteApiCity").kendoAutoComplete({
                minLength: 5,
                dataTextField: "city",
                template: kendo.template($("#template").html()),
                ignoreCase: true,
                filter: "contains",
                dataSource: new kendo.data.DataSource({
                    transport: {
                        read: function (options) {
                            var value;
                            if ($("#autoCompleteApiCity").data("kendoAutoComplete") === undefined) {
                                options.success([]);
                                return;
                            }
                            else {
                                value = $("#autoCompleteApiCity").data("kendoAutoComplete").value();
                                if (value === '' || value === undefined || value === null) {
                                    options.success([]);
                                    return;
                                }
                            }

                            $.ajax({
                                url: UrlAddressAutoComplete.ApiAdresseGouv + value,
                                type: "GET",
                                dataType: 'json',
                                success: function (data) {
                                    options.success($.map(data.features, function (item) {
                                        return window.cityFormatter(item);
                                    }));
                                    return;
                                },
                                error: function () {
                                    options.error([]);
                                    return;
                                }
                            });
                        }
                    },
                    serverFiltering: true
                }),
                change: function () {
                    this.dataSource.read();
                },
                select: function (e) {
                    var item = e.dataItem;
                    $('.js-api-zipcode').val(item.postcode);
                }
            });
        });

        window.addressFormatter = function (item) {
            return {
                label: item.properties.label,
                street: item.properties.name,
                postcode: item.properties.postcode,
                citycode: item.properties.citycode,
                city: item.properties.city,
                value: item.properties.label
            };
        }

        window.zipCodeFormatter = function (item) {
            return {
                label: item.properties.postcode + ' - ' + item.properties.city,
                citycode: item.properties.citycode,
                city: item.properties.city,
                value: item.properties.postcode,
            };
        }

        window.cityFormatter = function (item) {
            return {
                label: item.properties.postcode + ' - ' + item.properties.city,
                postcode: item.properties.postcode,
                citycode: item.properties.citycode,
                city: item.properties.city,
                value: item.properties.city
            };
        }
    }
}