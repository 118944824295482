export default class LoginFormActions {
    constructor($view) {
        this.view = $view;
        this.bind();
    }

    bind() {
        window.ConnexionSuccessLogin = function (data) {
            if (data.Success == "Ok") {
                window.location.href = data.Url;
            }
            else if (data.Success == "Ko") {
                Swal.fire(Resource.Connexion, data.Message, "error");
            }
            else {
                $("#FormConnexion").html(data);
            }
        }

        window.ConnexionErrorLogin = function (data) {
            Swal.fire(LoginFormActionsResx.ConnexionErrorLoginTitle, LoginFormActionsResx.ConnexionErrorLoginMessage, "error");
        }
    }
}
