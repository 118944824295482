export default class TabHelperComponent {
    constructor($view) {
        this.view = $view;
        this.bind();
    }

    bind() {
        $.fn.tabHelperComponent = {
            initTabButton: function () {
                $(".wipozTab").click(function (event) {
                    var targetWipozTab = event.target;

                    if (!$(event.target).hasClass("wipozTab")) {
                        targetWipozTab = $(event.target).parents(".wipozTab");
                    }

                    $(".wipozTab").removeClass("active");
                    $(".wipozTabPanel").removeClass("active");

                    var dataTarget = $(targetWipozTab).data("target");
                    $("a.wipozTab[data-target='" + dataTarget + "']").addClass("active");
                    $(dataTarget).addClass("active");
                });
            },

            OpenModalAddDocument: function () {
                if ($("#PointEntree").val() === "email") {
                    $.ajax({
                        url: urlActionHomeActions.GetModalDocument,
                        method: "POST",
                        async: false,
                        data: { Wipoz_ClientID: $("#Wipoz_ClientID").val()},
                        success: function (html) {
                            let $btnConfirm = $("#Modal").find('.modal-confirm');
                            $("#Modal .modal-title").html(html.title);
                            $("#Modal .modal-dialog").addClass(html.stylelayout);
                            $("#Modal .modal-body").html(html.content);
                            window.Kernel.mountComponents($('#Modal'));
                            $("#Modal").modal("show");
                            $btnConfirm.on("click", function () {
                                $("#form-modal-newdoc-home").kendoValidator().data("kendoValidator");
                                $("#form-modal-newdoc-home").submit();
                            });
                        },
                        error: function () {
                            toastr.error("La publication n'est pas possible");
                        }
                    });
                }
            }
        }

        $(document).ready(function () {
            $.fn.tabHelperComponent.initTabButton();

            $.fn.tabHelperComponent.OpenModalAddDocument();
        });
    };
}