export default class CBSkillSectionLink {
    constructor($view) {
        this.view = $view;
        this.bind($view);
    }
    bind($view) {
        $($view).on("change", function () {
            var skill = $(this).data("skill");
            if (skill == "0") {
                return;
            }
            if ($(this).is(':checked')) {
                //activate skill
                $.fn.createOrEditInfoTechniques.selectTypeCompetence(skill, true);
            }
        });
    };
}