export default class KendoTabNavigator {
    constructor($view) {
        this.view = $view;
        this.bind($view);
    }
    bind($view) {
        var kendoTabStrip = $view.data("kendoTabStrip");
        kendoTabStrip.bind("select", function (e) {
            var index = $(e.item).data("sectionIndex");
            var count = $(e.item).data("sectionCount");
            if (index != (count - 1)) {
                var next = index + 1;
                kendoTabStrip.enable($("#tab-" + next), true);
            }
            else {
                $.fn.createOrEdit.activateNext();
                kendoTabStrip.unbind("select");
            }
        });
    };
}