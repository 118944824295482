export default class MeasurementDone {
    constructor($view) {
        this.view = $view;
        this.bind();
    }

    bind() {
        $(document).ready(function () {
            var submitButton = $('button[type=submit]');
            submitButton.attr('title', submitButton.data('disabledTitle'));
        });
        $.fn.MeasurementDone = {
            OnBegin: function () {
                Swal.showLoading();
            },
            OnSuccess: function (data) {
                $('#ModalCustom').modal('hide');
                window.location.reload();
            },
            OnFailure: function (response) {
                Swal.close();
                if (response.statusText) {
                    toastr.error(response.statusText);
                }
                else {
                    toastr.error(ResourcePublication.ImpossiblePublierProjet);
                }
                return false;
            }
        };

    }
}