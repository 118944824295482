export default class FileGridEditCell {
    constructor($view) {
        this.view = $view;
        this.bind($view);
    }
    bind($view) {
        $.fn.FileGridCell = {
            onKeyUp: function (keyUpEvent) {
                if (keyUpEvent.key == 'Enter') {
                    var cell = $(keyUpEvent.target).closest('td');
                    var p = cell.closest(".k-grid");
                    var grid = $(p).data("kendoGrid");
                    grid.closeCell(cell);
                    grid.dataSource.sync();
                }
                else if (keyUpEvent.key == "Escape") {
                    var cell = $(keyUpEvent.target).closest('td');
                    var p = cell.closest(".k-grid");
                    var grid = $(p).data("kendoGrid");
                    grid.cancelChanges();
                    grid.closeCell();
                }
            },
            onCellEdit: function (e) {
                var input = e.container.find("input.k-textbox");
                if (input) {
                    $(input).on('keyup', $.fn.FileGridCell.onKeyUp); 
                    
                }
            }
        };
    };
}