export default class SuiviLivraison {
    constructor($view) {
        this.view = $view;
        this.bind();
    }
    bind() {

        $.fn.SuiviLivraison = {
            onDataBound: function (e) {
                var grid = $("#GridOrderNumber").data("kendoGrid");
                for (let data of grid.dataSource.data()) {
                    var btnDetails = grid.table.find("tr[data-uid='" + data.uid + "']").find(".k-grid-details");
                    if (data.NbItems == 0) {
                        btnDetails.remove();
                    }
                }
                window.Kernel.mountComponents($('#GridOrderNumber'));
            },
            onEdit: function (e) {
                var btnDetail = $(e.container).find(".k-grid-details");
                btnDetail.hide();
                $.fn.Grid.onEdit(e);
            },
            onCancel: function (e) {
                setTimeout(function () {
                    window.Kernel.mountComponents($('#GridOrderNumber'));
                }, 250);
            },
            add: function (e) {
                var grid = $("#GridOrderNumber").data("kendoGrid");
                grid.addRow();
            },
            updateStatus: function (url) {
                Swal.fire({
                    title: Resource.Patienter,
                    allowOutsideClick: () => !Swal.isLoading(),
                    onAfterClose: function () {
                        $(window).scrollTop(0);
                    }
                });
                Swal.showLoading();

                $.ajax({
                    url: url,
                    method: "GET",
                    success: function (data) {
                        Swal.close();
                        var grid = $("#GridOrderNumber").data("kendoGrid");
                        grid.dataSource.read();
                    },
                    error: function (e) {
                        Swal.close();
                        toastr.error("Une erreur est survenue lors du traitement");
                    }
                });
            },
            delete: function (e) {
                let dataItem = this.dataItem($(e.currentTarget).closest("tr"));

                Swal.fire({
                    title: AlertsActionsRsx.Title_SupprimerCommande,
                    text: AlertsActionsRsx.Message_Confirmation_SupprimerCommande,
                    type: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#2ABFBA',
                    cancelButtonColor: '#E0E0E0',
                    cancelButtonText: ResourceFiche.Non,
                    confirmButtonText: ResourceFiche.Oui,
                    showLoaderOnConfirm: () => !Swal.isLoading(),
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    preConfirm: () => {
                        $.ajax({
                            url: urlActionFicheAlertsActions.actionSupprimerOrderNumber,
                            method: "POST",
                            data: {
                                id: dataItem.Wipoz_ProjetOrderNumberID,
                            },
                            success: function (data) {
                                Swal.close();
                                if (data.success == 1) {
                                    toastr.success(data.message);
                                    var grid = $("#GridOrderNumber").data("kendoGrid");
                                    grid.dataSource.remove(dataItem);
                                    grid.dataSource.sync();
                                    grid.dataSource.read();
                                }
                                else
                                {
                                    toastr.error(data.message);
                                }
                            },
                            error: function (e) {
                                Swal.close();
                                console.log(e);
                            }
                        });
                    }
                }).then(() => {
                    
                });
            },
            getSupplierId: function () {
                let grid = $("#GridOrderNumber").data("kendoGrid");
                let datas = grid.dataItem($("#GridOrderNumber").find(".k-grid-edit-row"))

                if (datas == null
                    || datas.Supplier == null) {
                    return {
                        id: null
                    };
                }
                else {
                    return {
                        id: datas.Supplier.Value
                    };
                }
            },
            openModalSuppliers: function () {
                $("#addSupplier").click();
                // On ferme la ligne de création
                var grid = $("#GridOrderNumber").data("kendoGrid");
                grid.cancelRow();
            },
            elementSelected: function (e) {
                if (e.dataItem.IsCommand) {
                    $.fn.SuiviLivraison.openModalSuppliers();
                }
                return e;
            },
            getId: function (e) {
                let dataItem = $("#GridOrderNumber").data("kendoGrid").dataItem($(e.currentTarget).closest("tr"));
                return { id: dataItem.Wipoz_ProjetOrderNumberID };
            }
        }
    }
}