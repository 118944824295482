export default class createOrEditInfoTechniques {

    constructor($view) {
        this.view = $view;
        this.bind();
    }

    bind() {
        $("#form-projet").kendoValidator({
            validate: function (ev) {
                $(".k-invalid:first").focus();
            }
        });

        var completeDescription = $(this.view).data('completeDescription');
        $("#applicationChangement").prop("disabled", true);
        $("#applicationChangement").css('cursor', 'not-allowed');
        $("#applicationChangement").prop('title', completeDescription);

        $("#etapeSuivanteValidForm").prop("disabled", true);
        $("#etapeSuivanteValidForm").css('cursor', 'not-allowed');
        $("#etapeSuivanteValidForm").prop('title', completeDescription);

        $(document).ready(function () {

            $('#implantation0').on('click', () => {
                $('#Implantation_validationMessage').addClass("hidden");
            });

            $('#implantation1').on('click', () => {
                $('#Implantation_validationMessage').addClass("hidden");
            });

            $('#implantation2').on('click', () => {
                $('#Implantation_validationMessage').addClass("hidden");
            });

            $('#livraison0').on('click', () => {
                $('#Livraison_validationMessage').addClass("hidden");
            });

            $('#livraison1').on('click', () => {
                $('#Livraison_validationMessage').addClass("hidden");
            });

            $('#Ilot1').on('click', () => {
                $('#Ilot_validationMessage').addClass("hidden");
            });

            $('#Ilot2').on('click', () => {
                $('#Ilot_validationMessage').addClass("hidden");
            });
        });

        $.fn.createOrEditInfoTechniques = {
            selectTypeCompetence: function (typeCompetence, activeOnly) {
                if (activeOnly !== undefined && activeOnly === true && $(".btn-competence[value='" + typeCompetence + "']").hasClass("active")) {
                    return;
                }

                $(".btn-competence[value='" + typeCompetence + "']").toggleClass("active");

                // Récupere les ID des competences sélectionnées sous forme de STRING du type "{id},{id}" : eg: "8,2,4"
                var competences = $.map($(".btn-competence.active"),
                    function (btn) {
                        return $(btn).attr("value")
                    }
                ).join();

                $("#CompetencesProxy").val(competences);
            },

            OnBeginEtapeInfoTechniques: function () {
                var NoError = true;
                if ($('#IsCheckedChecbox').val() === "1") {
                    if (!$('#implantation0').is(':checked') && !$('#implantation1').is(':checked') && !$('#implantation2').is(':checked') && !$('#implantation3').is(':checked')) {
                        $('#Implantation_validationMessage').removeClass("hidden");
                        NoError = false;
                    }

                    if (!$('#livraison0').is(':checked') && !$('#livraison1').is(':checked')) {
                        $('#Livraison_validationMessage').removeClass("hidden");
                        NoError = false;
                    }

                    if (!$('#Ilot1').is(':checked') && !$('#Ilot2').is(':checked')) {
                        $('#Ilot_validationMessage').removeClass("hidden");
                        NoError = false;
                    }
                }
                if (!NoError) {
                    Swal.close();
                }
                return NoError;
            }
        };
    }
}