
export default class CommonFormActions {
    constructor($view) {
        this.bind();
        this.view = $view;
    }

    bind() {
        window.loadFormActions = function () {
            $(".js-input-group").each((index, el) => {
                var element = $(el);
                if (element.val() !== null && element.val() !== "") {
                    element.addClass("filled");
                } else {
                    element.removeClass("filled");
                }
            });

            $(".js-input-group").on("blur", (element) => {
                var el = $($(element.currentTarget));
                if (el.val() !== null && el.val() !== "") {
                    el.addClass("filled");
                } else {
                    el.removeClass("filled");
                }
            });
        }

        loadFormActions();
    }
}
