export default class Assignment {
    constructor($view) {
        this.view = $view;
        this.bind($view);
    }

    bind() {
        var url = $(this.view).data("assignmentUrl");
        var scheduler = $(this.view).data("assignmentScheduler");
        var successMessage = $(this.view).data("assignmentSuccess");
        $(this.view).find("[data-assignment-target]").on("click", function () {
            var projectId = $(this).data("assignmentTarget");
            var collaboratorId = $(this).data("assignmentClient");
            $.ajax({
                url: url,
                method: "POST",
                data: {
                    Wipoz_ProjetID: projectId,
                    idCollaborateur: collaboratorId
                },
                success: function () {
                    if (successMessage) {
                        toastr.success(successMessage);
                    }

                    if (scheduler) {
                        $(scheduler).data("kendoScheduler").dataSource.read();
                        $(scheduler).data("kendoScheduler").refresh();
                    }
                }
            })
        });
    }
}