export default class kWindow {
    constructor($view) {
        this.view = $view;
        this.bind();
    }

    bind() {
        var command = $(this.view);
        var refreshUrl = command.data("url");
        if (refreshUrl && refreshUrl != '') {

            var windowId = command.data("windowId");

            $(() => {
                var $window = $("#" + windowId);
                var kendoWindow = $window.data("kendoWindow");
                var w = window,
                    d = document,
                    e = d.documentElement,
                    g = d.getElementsByTagName('body')[0],
                    x = w.innerWidth || e.clientWidth || g.clientWidth;
                kendoWindow.setOptions({ maxWidth: x / 2, minWidth: x / 3 });
                kendoWindow.bind("refresh", function () {
                    window.Kernel.mountComponents($window);
                });
            });
            command.on("click", function (e) {
                e.preventDefault();
                var kendoWindow = $("#" + windowId).data("kendoWindow");
                kendoWindow
                    .content('<div class="w-100 text-center"><i class="fa fa-spinner fa-pulse fa-2x fa-fw"></i></div>')
                    .refresh({ url: refreshUrl, cache: false, data: { windowId: windowId }, type: 'POST' })
                    .open()
                    .center();
            });
        }
    }
}