export default class Interessement {
    constructor($view) {
        this.view = $view;
        this.bind();
    }

    bind() {

        $(document).ready(function () {
            $('#BoutonConfirmationEngagement').click($.fn.ConfirmationEngagement.ConfirmationEngagement);

            $('#CheckboxDisponibleDate').on('click', () => {
                document.getElementById('errorMsgCheckboxDisponibleDate').innerHTML = "";
            })

            $('#CheckboxElementProjet').on('click', () => {
                document.getElementById('errorMsgCheckboxElementProjet').innerHTML = "";
            })

            $('#CheckboxCompetences').on('click', () => {
                document.getElementById('errorMsgCheckboxCompetences').innerHTML = "";
            })
        });

        $.fn.ConfirmationEngagement = {
            ConfirmationEngagement: function () {
                var IsCheckboxDisponibleDate = document.getElementById('CheckboxDisponibleDate').checked;
                var IsCheckboxElementProjet = document.getElementById('CheckboxElementProjet').checked;
                var IsCheckboxCompetences = document.getElementById('CheckboxCompetences').checked;

                if (IsCheckboxDisponibleDate && IsCheckboxElementProjet && IsCheckboxCompetences) {
                    Swal.fire({
                        title: Resource.ChargementValiderInteressement,
                        allowOutsideClick: () => !Swal.isLoading()
                    });
                    Swal.showLoading();

                    $("#form-modal-interessement").submit();
                }
                else {
                    if(!IsCheckboxDisponibleDate) {
                        document.getElementById('errorMsgCheckboxDisponibleDate').innerHTML = Resource.ConfirmationEngagementChecboxObligatoire;
                    }

                    if (!IsCheckboxElementProjet) {
                        document.getElementById('errorMsgCheckboxElementProjet').innerHTML = Resource.ConfirmationEngagementChecboxObligatoire;
                    }

                    if (!IsCheckboxCompetences) {
                        document.getElementById('errorMsgCheckboxCompetences').innerHTML = Resource.ConfirmationEngagementChecboxObligatoire;
                    }
                }
            }
        };

        window.InteressementSuccess = function (data) {
            Swal.close();
            if (data.projetIsPourvu == 1) {
                alertProjetNonDisponible();
            } else if (data.projetIsPourvu == -1) {
                Swal.fire(data.status, data.message, "error").then(function () {
                    window.location.assign(data.url);
                });
            } else if (data.projetIsPourvu == 2) {
                Swal.fire(data.status, data.message, "error");
            } else {
                location.reload();
            }
        };

        window.InteressementError = function (jqXHR) {
            Swal.close();
            if (jqXHR.status == 403) {
                if (jqXHR.responseJSON.url) {
                    window.location.assign(jqXHR.responseJSON.url);
                }
                else if (jqXHR.responseJSON.message) {
                    toastr.error(jqXHR.responseJSON.message);
                }
            }
            else {
                toastr("Impossible de confirmer");
            }
        }; 

        function alertProjetNonDisponible() {
            Swal.fire({
                title: Resource.ProjetNonDisponibleTitre,
                text: Resource.ProjetNonDisponibleTexte,
                type: 'warning',
                confirmButtonColor: '#2ABFBA',
                confirmButtonText: Resource.ProjetNonDisponibleRetour,
                showLoaderOnConfirm: true
            }).then((result) => {
                window.location.href = "/Dashboard";
            });
            return false;
        };
    }
}