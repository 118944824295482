// Export necessaire par défaut
// Fonction lambda


//hack pour pouvoir déclarer une fonction global en TS
//declare var $: any;
//declare var WebpackApp: any;

export default ($view) => {

    $view.on("click", (event) => {
        //stop la propagation de l'évènement click
        Swal.fire({
            title: Resource.Patienter,
            allowOutsideClick: () => !Swal.isLoading(),
            onAfterClose: function () {
                $(window).scrollTop(0);
            }
        });
        Swal.showLoading();
        event.preventDefault();       

        var param = {};
        if ($view.data('parametersFunction')) {
            param = convertStringtoFunction($view.data('parametersFunction'))(event);
        }
        else if ($view.data('parametersData')) {
            param = JSON.parse($view.data('parametersData'));
        }

        if ($view.data('loadMount')) {
            window.Kernel.createComponent($view.data('loadMount'), $view);
        }

        $.ajax({
            url: $view.data('url'),
            method: $view.data('method') ? $view.data('method') : "POST",
            data: param,
            success: function (data) {
                Swal.close();
                let $modal = $("#Modal");
                let $btnConfirm = $modal.find('.modal-confirm');
                $modal.find(".modal-dialog").addClass(data.stylelayout);
                $modal.find(".modal-title").html(data.title);
                $modal.find(".modal-body").html(data.content);
                $modal.find(".modal-cancel").html(data.cancelLabel);                             
                $modal.find(".modal-confirm").html(data.confirmLabel);
                if ((data.dataKeyboard != undefined) && (data.dataKeyboard != '')) {
                    $modal.data("keyboard", data.dataKeyboard);  
                }
                if ((data.dataBackdrop != undefined) && (data.dataBackdrop != '')) {
                    $modal.data("backdrop", data.dataBackdrop);
                }           
                window.Kernel.mountComponents($($modal));
                $($modal).modal("show");
                if ((data.confirmLabel != undefined) && (data.confirmLabel != '')) {
                    $modal.find(".modal-confirm").show();
                    //decharge les event click sur le bouton de confirmation
                    $btnConfirm.unbind("click");
                    //distribuer l'événement.
                    $btnConfirm.on("click", function () {
                        //console.log($view);
                        $view.trigger('modalAjaxConfirm');
                    });
                }
                else {
                    $modal.find(".modal-confirm").hide();
                }
            },
            error: function (e) {
                Swal.close();
                console.log("erreur");
                if (e.responseJSON
                    && e.responseJSON.message) {
                    toastr.error(e.responseJSON.message);
                }
                else {
                    toastr.error(Resource.ErrorLoadingModal);
                }
            }
        });
    });
} 

window.DemandeSuccess = function (data) {
    $("#Modal").modal("hide");
    Swal.fire(Resource.Title_Demande, Resource.Demande_Envoi_Success, "success");
}

window.DemandeFailure = function () {
    Swal.fire(Resource.Title_Demande, Resource.Demande_Envoi_Erreur, "error");
}

$(document).on("modalAjaxConfirm", function (event) {
    var $view = $(event.target);
    var id = $view.attr("id");

    var parts = id.split('-');
    var loc = parts[0];

    switch (loc) {
        case "newProduit":
        case "editProduit":
            //Client - Fiche Produit
            var validatable = $("#form-modal-produit").kendoValidator().data("kendoValidator");
            $("#form-modal-produit").submit();
            break;
        case "deleteProduit":
        case "deleteDocument":
        case "demandeContact":
        case "demandeProjet":
        case "demandeRelation":
        case "DemandePartenaireInfo":
            $("#form-modal-demande").kendoValidator().data("kendoValidator");
            $("#form-modal-demande").submit();
            break;
        case "newProjet":
        case "editProjet":
            //Projet - Fiche
            $("#form-modal-projet").kendoValidator().data("kendoValidator");
            $("#form-modal-projet").submit();
            break;
        case "AddDocCuisinisteProjet":
            //Projet - Fiche
            $("#formModalDoc").kendoValidator().data("kendoValidator");
            $("#formModalDoc").submit();
            break;

        case "confirmProjet":
            $("#form-modal-confirm-projet").submit();
            break;

        case "newDoc":
            $("#form-modal-newdoc").kendoValidator().data("kendoValidator");
            $("#form-modal-newdoc").submit();
            break;
        case "creerDocument":
            $("#form-modal-newdoc-home").kendoValidator().data("kendoValidator");
            $("#form-modal-newdoc-home").submit();
            break;
        case "changeAvatar":
            $("#form-modal-avatar").kendoValidator().data("kendoValidator");
            $("#form-modal-avatar").submit();
            break;

        default:
            toastr.error("Erreur : modalAjaxConfirm (" + $view.attr("id") + ")");
    }
});