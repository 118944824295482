export default class FileRequiredFormActions {
    constructor($view) {
        this.view = $view;
        this.bind();
    }

    bind() {

        //TODO - 2020/04/21 - HML - A refacto si utile plus tard
        var listRequiredFile = [
            'KBIS'
        ];

        $(function () {
            $.fn.fileRequiredFormActions.actionOnRequiredFile(true);
        });

        $.fn.fileRequiredFormActions = {

            actionOnRequiredFile: function (isInit) {
                listRequiredFile.forEach(element => {
                    var data = {
                        idNameFileUploader: '#' + element + 'fileUploader',
                        typeNameFile: element,
                        classNameErrorField: '.' + element + 'FieldError',
                        inputNameFile: 'input[name=' + element + ']'
                    };

                    $(data.idNameFileUploader).change(function () {
                        $(data.idNameFileUploader).ready(function () {
                            $.fn.fileRequiredFormActions.checkRequiredFile(data);
                        });
                    });

                    $(".inscriptionBtn").click(function () {
                        $.fn.fileRequiredFormActions.checkRequiredFile(data);
                    });
                    var fileTitle = $("#" + element).text();

                    if ($(data.inputNameFile).length > 0 && $(data.classNameErrorField).val() === undefined) {
                        if ($('input[name=IsIntervenant]:checked').val() == 'False') {
                            $.fn.fileRequiredFormActions.errorRequiredFileMsg(data);
                        }

                        if (isInit) {
                            $(data.classNameErrorField).hide();
                        }
                    }

                    if ($('input[name=IsIntervenant]:checked').val() == 'False') {
                        $("#" + element).text($.fn.fileRequiredFormActions.toggleStar(fileTitle, true));
                    }

                    $('input[name=IsIntervenant]').change(function () {
                        fileTitle = $("#" + element).text();
                        if ($('input[name=IsIntervenant]:checked').val() == 'True') {
                            if ($(data.classNameErrorField).length > 0) {
                                $(data.classNameErrorField).hide();
                            }
                            $("#" + element).text($.fn.fileRequiredFormActions.toggleStar(fileTitle, false));
                        } else {
                            $("#" + element).text($.fn.fileRequiredFormActions.toggleStar(fileTitle, true));
                            if ($(data.classNameErrorField).length > 0) {
                                $(data.classNameErrorField).show();
                            }
                        }
                    })
                });
            },

            toggleStar: function (text, add) {
                if ((text === null) || (text === undefined)) return '';
                if (text.length < 2) return text;
                if (add) {
                    if (text.charAt(text.length - 1) !== "*") {
                        return text + " *";
                    }
                }
                else if (text.charAt(text.length - 1) === "*") {
                    return text.substring(0, text.length - 2);
                }
                return text;
            },

            errorRequiredFileMsg: function (data) {
                var kBisParent = $(data.typeNameFile).parent().parent();
                kBisParent.append('<span class="field-validation-error '
                    + data.classNameErrorField.substring(1)
                    + '" data-valmsg-for="' + data.typeNameFile
                    + '" data-valmsg-replace="true"></span>');
                $(data.classNameErrorField).append('<span id="' + data.typeNameFile + '-error"></span>');
                $('#' + data.typeNameFile + '-error').append($.fn.fileRequiredFormActions.switchCaseErrorFile(data.typeNameFile));
            },

            switchCaseErrorFile: function (element) {
                if (element == 'KBIS') { return Resource.kBisRequired; }
                else { return ""; }
            },

            checkRequiredFile: function (data) {
                var file = $(data.idNameFileUploader).closest(".k-upload").find(".k-file").length;
                if (file == 0) {
                    if ($('input[name=IsIntervenant]:checked').val() == 'False') {
                        if ($(data.classNameErrorField).val() !== undefined) {
                            if ($(data.classNameErrorField).length > 0) {
                                $(data.classNameErrorField).show();
                            }
                        } else {
                            $.fn.fileRequiredFormActions.errorRequiredFileMsg(data);
                        }
                        if ($(data.inputNameFile).val() !== "") {
                            $(data.inputNameFile).val("");
                        }
                    }
                }
                else {
                    $(data.classNameErrorField).hide();
                    $(data.inputNameFile).val("Uploaded");
                }
            }
        }
    }
}