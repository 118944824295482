export default class MotifRefus {
    constructor($view) {
        this.view = $view;
        this.bind();
    }

    bind() {
        $(document).ready(function () {

            let formProjet = $("#form-projet-MotifRefus");
            kendo.init(formProjet);
            window.kendoValidator = formProjet.kendoValidator({
                validate: function () {
                    $(".k-invalid:first").focus();
                },
                rules: {
                    
                }
            }).data("kendoValidator");

            $("#datePasAdapterForm").attr('style', 'display:none');
            $("#datePasAdapter").val("");
            $("#datePasAdapter").removeAttr("data-val-required");

            $("#commentaireManqueElementsForm").attr('style', 'display:none');
            $("#commentaireManqueElements").val("");
            $("#commentaireManqueElements").removeAttr("data-val-required");

            $("#prixPasAdapterForm").attr('style', 'display:none');
            $("#prixPasAdapter").val("");
            $("#prixPasAdapter").removeAttr("data-val-required");
            $("#prixPasAdapterComment").attr('style', 'display:none');
            $("#commentairePrix").removeAttr("data-val-required");

            $("#commentaireAutreForm").attr('style', 'display:none');
            $("#commentaireAutre").val("");
            $("#commentaireAutre").removeAttr("data-val-required");

            $("#checkBoxPasDispo").on("click", function () {
                if ($(this).is(":checked")) {
                    $("#datePasAdapterForm").removeAttr('style');
                    $("#datePasAdapter").attr("data-val-required", ResourceMotifRefus.ChampObligatoire);
                    window.kendoValidator.validate();
                }
                else {
                    $("#datePasAdapterForm").attr('style', 'display:none');
                    $("#datePasAdapter").val("");
                    $("#datePasAdapter").removeAttr("data-val-required");
                    window.kendoValidator.validate();
                }
            });

            $("#checkBoxManqueElements").on("click", function () {
                if ($(this).is(":checked")) {
                    $("#commentaireManqueElementsForm").removeAttr('style');
                    $("#commentaireManqueElements").attr("data-val-required", ResourceMotifRefus.ChampObligatoire);
                    window.kendoValidator.validate();
                }
                else {
                    $("#commentaireManqueElementsForm").attr('style', 'display:none');
                    $("#commentaireManqueElements").val("");
                    $("#commentaireManqueElements").removeAttr("data-val-required");
                    window.kendoValidator.validate();
                }
            });

            $("#checkBoxPrixPasAdapte").on("click", function () {
                if ($(this).is(":checked")) {
                    $("#prixPasAdapterForm").removeAttr('style');
                    $("#prixPasAdapter").attr("data-val-required", ResourceMotifRefus.ChampObligatoire);
                    $("#prixPasAdapterComment").removeAttr('style', 'display:none');
                    $("#commentairePrix").attr("data-val-required", ResourceMotifRefus.ChampObligatoire);
                    window.kendoValidator.validate();
                }
                else {
                    $("#prixPasAdapterForm").attr('style', 'display:none');
                    $("#prixPasAdapter").val("");
                    $("#prixPasAdapter").removeAttr("data-val-required");
                    $("#prixPasAdapterComment").attr('style', 'display:none');
                    $("#commentairePrix").removeAttr("data-val-required");
                    window.kendoValidator.validate();
                }
            });

            $("#checkBoxAutre").on("click", function () {
                if ($(this).is(":checked")) {
                    $("#commentaireAutreForm").removeAttr('style');
                    $("#commentaireAutre").attr("data-val-required", ResourceMotifRefus.ChampObligatoire);
                    window.kendoValidator.validate();
                }
                else {
                    $("#commentaireAutreForm").attr('style', 'display:none');
                    $("#commentaireAutre").val("");
                    $("#commentaireAutre").removeAttr("data-val-required");
                    window.kendoValidator.validate();
                }
            });
        });

        $.fn.MotifRefus = {
            OnFailureMotifRefus: function () {
                toastr.error(ResourceMotifRefus.Modal_Refus_Failed);
            },

            OnSuccessMotifRefus: function (data) {
                Swal.close();
                $('#ModalCustom').modal('hide');
                if (data.success == 0) {
                    toastr.error(data.message);
                }
                else {
                    toastr.success(ResourceMotifRefus.Modal_Refus_Success);
                }
            },

            OnBeginMotifRefus: function () {
                Swal.fire({
                    title: ResourceMotifRefus.EnvoiDesNotifications,
                    allowOutsideClick: () => !swal.isLoading()
                });
                Swal.showLoading();
            }
        }
    }
}