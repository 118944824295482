export default class FileGridRemoveCommand {
    constructor($view) {
        this.view = $view;
        this.bind($view);
    }
    bind($view) {
        $.fn.FileGrid = {
            onFileRemove: function (e) {
                e.preventDefault();
                var tr = $(e.target).closest("tr"); //get the row for deletion
                var data = this.dataItem(tr); //get the row data so it can be referred later
                var grid = this;
                Swal.fire({
                    title: ResourceDocument.SuppressionDocument,
                    text: ResourceDocument.VoulezVousSupprimerDocument + data.Label + " ?",
                    type: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#2ABEBA',
                    cancelButtonText: ResourceDocument.Non,
                    confirmButtonText: ResourceDocument.Oui,
                    showLoaderOnConfirm: true,
                    preConfirm: () => {
                        grid.dataSource.remove(data);
                        grid.dataSource.sync();
                    },
                    allowOutsideClick: () => !Swal.isLoading()
                }).then((result) => {
                });
            },
            onFileRemoved: function (e) {
                toastr.success(ResourceDocument.DocumentSupprimeSuccess);
            }
        };
    };
}