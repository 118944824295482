export default class CGU {
    constructor($view) {
        this.view = $view;
        this.bind($view);
    }

    bind($view) {
        this.view = $($view);
        this.cguUrl = $view.data("cgu-url");
        window.GetModalCgu = function () {
            const mainWrapper = $(".main-wrapper");
            $.ajax({
                url: mainWrapper.data("cgu-modal-url"),
                method: "GET",
                async: false, // Mode synchrone
                success: function (data) {
                    $("#Modal")
                        .html(data)
                        .modal({ backdrop: 'static', keyboard: false });
                }
            });
        }

        this.CheckCguIsValid();
    }


    CheckCguIsValid() {
        $.ajax({
            url: this.cguUrl,
            method: "GET",
            async: false, // Mode synchrone
            success: function (data) {
                if (!data.cguValid) {
                    GetModalCgu();
                }
            }
        });
    }

    
}