export default class DashboardFilters {
    constructor($view) {
        this.view = $view;
        this.bind();

    }
    bind() {
        if ($(window).width() < 610) {
            $(".column-header").addClass("column-clickable");
        }

        $(".card-footer-preview").on("click", function () {
            $(this).parent().find(".card-footer-details").toggleClass("closed");
            $(this).find(".fa-chevron-down").toggleClass("rotate-180");
        })
        $(".column-header").on("click", function () {
            if ($(window).width() < 610) {
                $(this).parent().find(".cards").toggleClass("cards-collapse");
                $(this).find(".column-collapse-icon .fa-chevron-down").toggleClass("rotate-180");
            }
        })

        $("#keywordForm").submit(function (event) {
            event.preventDefault();
            keywordSelected = $("#keyword").val().toLowerCase();
            updateBoard();
        });

        window.filtersSelected = [];
        window.typeSelected = [];
        window.mlpSelected = [];
        window.stateSelected = [];
        window.proprietaireSelected = [];
        window.partnerSelected = [];
        window.keywordSelected = "";

        window.addFilter = function (type, param) {
            var paramFilter = type + '-' + param;
            $('#' + paramFilter).addClass("filter-selected");
            switch (type) {
                case 'mlp':
                    if (mlpSelected.indexOf(param) === -1) {
                        mlpSelected.push(param);
                        filtersSelected.push(paramFilter);
                    }
                    else {
                        mlpSelected.splice($.inArray(param, mlpSelected), 1);
                        filtersSelected.splice($.inArray(paramFilter, filtersSelected), 1);
                        $('#' + paramFilter).removeClass("filter-selected");
                    }
                    break;
                case 'type':
                    if (typeSelected.indexOf(param) === -1) {
                        typeSelected.push(param);
                        filtersSelected.push(paramFilter);
                    }
                    else {
                        typeSelected.splice($.inArray(param, typeSelected), 1);
                        filtersSelected.splice($.inArray(paramFilter, filtersSelected), 1);
                        $('#' + paramFilter).removeClass("filter-selected");
                    }
                    break;
                case 'state':
                    if (stateSelected.indexOf(param) === -1) {
                        stateSelected.push(param);
                        filtersSelected.push(paramFilter);
                    }
                    else {
                        stateSelected.splice($.inArray(param, stateSelected), 1);
                        filtersSelected.splice($.inArray(paramFilter, filtersSelected), 1);
                        $('#' + paramFilter).removeClass("filter-selected");
                    }
                    break;
                case 'proprietaire':
                    if (proprietaireSelected.indexOf(param) === -1) {
                        proprietaireSelected.push(param);
                        filtersSelected.push(paramFilter);
                    }
                    else {
                        proprietaireSelected.splice($.inArray(param, proprietaireSelected), 1);
                        filtersSelected.splice($.inArray(paramFilter, filtersSelected), 1);
                        $('#' + paramFilter).removeClass("filter-selected");
                    }
                    break;
                case 'partner':
                    if (partnerSelected.indexOf(param) === -1) {
                        partnerSelected.push(param);
                        filtersSelected.push(paramFilter);
                    }
                    else {
                        partnerSelected.splice($.inArray(param, partnerSelected), 1);
                        filtersSelected.splice($.inArray(paramFilter, filtersSelected), 1);
                        $('#' + paramFilter).removeClass("filter-selected");
                    }
                    break;
            }
            updateBoard();
        }
        window.removeFilter = function (type, param) {
            var paramFilter = type + '-' + param;
            $('#' + paramFilter).removeClass("filter-selected");
            switch (type) {
                case 'mlp':
                    if (mlpSelected.indexOf(param) !== -1) {
                        mlpSelected.splice($.inArray(param, mlpSelected), 1);
                    }
                    filtersSelected.indexOf(paramFilter) === -1 ? console.log("This item doesnt exist") : filtersSelected.splice($.inArray(paramFilter, filtersSelected), 1);
                    break;
                case 'type':
                    if (typeSelected.indexOf(param) !== -1) {
                        typeSelected.splice($.inArray(param, typeSelected), 1);
                    }
                    filtersSelected.indexOf(paramFilter) === -1 ? console.log("This item doesnt exist") : filtersSelected.splice($.inArray(paramFilter, filtersSelected), 1);
                    break;
                case 'state':
                    stateSelected.indexOf(param) === -1 ? console.log("This item doesnt exist") : stateSelected.splice($.inArray(param, stateSelected), 1);
                    filtersSelected.indexOf(paramFilter) === -1 ? console.log("This item doesnt exist") : filtersSelected.splice($.inArray(paramFilter, filtersSelected), 1);
                    break;
                case 'proprietaire':
                    proprietaireSelected.indexOf(param) === -1 ? console.log("This item doesnt exist") : proprietaireSelected.splice($.inArray(param, proprietaireSelected), 1);
                    filtersSelected.indexOf(paramFilter) === -1 ? console.log("This item doesnt exist") : filtersSelected.splice($.inArray(paramFilter, filtersSelected), 1)
                    break;
                case 'partner':
                    partnerSelected.indexOf(param) === -1 ? console.log("This item doesnt exist") : partnerSelected.splice($.inArray(param, partnerSelected), 1);
                    filtersSelected.indexOf(paramFilter) === -1 ? console.log("This item doesnt exist") : filtersSelected.splice($.inArray(paramFilter, filtersSelected), 1)
                    break;
            }
            if (mlpSelected.length > 0 || typeSelected.length > 0 || stateSelected.length > 0 || proprietaireSelected.length > 0 || partnerSelected.length > 0 || keywordSelected.length > 0) {
                updateBoard();
            }
            else {
                showAllBoard();
            }
        }
        window.showAllBoard = function () {
            $(".db-card").show(300);
            $(".filter-array").filter(function () {
                $(this).hide();
                for (var i = 0; i < filtersSelected.length; i++) {
                    if ($(this).data('filter').indexOf(filtersSelected[i]) > -1) {
                        $(this).show();
                    }
                }
            });
        }
        window.updateBoard = function () {
            $(".db-card").hide();
            $(".db-card").filter(function () {
                if (
                    (mlpSelected.length === 0 || mlpSelected.indexOf($(this).data('projectWipoz')) > -1)
                    &&
                    (typeSelected.length === 0 || typeSelected.indexOf($(this).data('projectType')) > -1)
                    &&
                    (stateSelected.length === 0 || stateSelected.indexOf($(this).data('projectState')) > -1
                        || stateSelected.indexOf($(this).data('projectStep')) > -1)
                    &&
                    (proprietaireSelected.length === 0 || proprietaireSelected.indexOf($(this).data('projectProprietaireId')) > -1)
                    &&
                    (partnerSelected.length === 0 || partnerSelected.indexOf($(this).data('projectPartnerId')) > -1)
                    &&
                    (keywordSelected.length === 0 || $(this).data('projectKeyword').toLowerCase().indexOf(keywordSelected) > -1)
                ) {
                    $(this).show(300);
                }
            });
            $(".filter-array").filter(function () {
                $(this).hide();
                if ($.inArray($(this).data('filter'), filtersSelected) != -1) {
                    $(this).show();
                }
            });
        }
    }
}